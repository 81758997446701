import { TableColumnsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../../../components/layouts/table/Table"
import { getSortIndexColumnBuilder } from "../../../../../../../components/pagedtable/columns/columnBuilder"
import { NamedColorTile } from "../../../../../../../components/tiles/namedcolortile/NamedColorTile"
import { OrderTypeExteriorColorItemDTO } from "../../../../../../../generated/pdsapi"
import { ExteriorColorSortField, ExteriorColorSortFieldEnum } from "../../../../../../../pdsapi"

type ExteriorColorItemCardColumnBuilder = ColumnBuilder<OrderTypeExteriorColorItemDTO, ExteriorColorSortFieldEnum>

export const OrderTypeExteriorColorsCardColumnsConfig: TableColumnsConfig<OrderTypeExteriorColorItemDTO, ExteriorColorSortFieldEnum> = ({
    sorting,
    setSorting,
}) => {
    const sortIndexColumn: ExteriorColorItemCardColumnBuilder = getSortIndexColumnBuilder((exteriorColor) => exteriorColor.sortIndex, {
        field: ExteriorColorSortField.SORT_INDEX,
        value: sorting,
        setValue: setSorting,
    })

    const optionCodeColumn: ExteriorColorItemCardColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (exteriorColor) => exteriorColor.optionCode,
    }

    const nameColumn: ExteriorColorItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (exteriorColor) => exteriorColor.name ?? "-",
    }

    const exteriorColorTypeColumn: ExteriorColorItemCardColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Color Type",
        },
        buildCellContent: (exteriorColor) => exteriorColor.colorTypeName ?? "-",
    }

    const colorColumn: ExteriorColorItemCardColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Color",
        },
        buildCellContent: (exteriorColor) =>
            exteriorColor.color ? <NamedColorTile label={exteriorColor.color.label} hexCode={exteriorColor.color.hexCode} /> : "-",
    }

    const tagColumn: ExteriorColorItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Tag",
        },
        buildCellContent: (exteriorColor) => (exteriorColor.tag ? <NamedColorTile label={exteriorColor.tag.label} hexCode={exteriorColor.tag.hexCode} /> : "-"),
    }

    return [sortIndexColumn, optionCodeColumn, nameColumn, exteriorColorTypeColumn, colorColumn, tagColumn]
}
