import { propertiesOf } from "../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../components/formelements/EditFormElementWrapper"
import { getSimpleFormWatch } from "../../../../../components/formelements/FormDisplayData"
import { getBoolFormElement } from "../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { getTagFormElement } from "../../../../../components/formelements/tagformelement/TagFormElement"
import { getTagsFormElement } from "../../../../../components/formelements/tagsformelement/TagsFormElement"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO } from "../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { InteriorColorTile } from "@finder/ui-kit"
import { FC } from "react"
import { useLocation } from "react-router-dom"

export const InteriorCustomizationCreateAttributesCard: FC<
    ProductCreateContentProps<InteriorCustomizationCreateRequestDTO, InteriorCustomizationCreateOptionsDTO>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<InteriorCustomizationCreateRequestDTO>()
    const searchParams = new URLSearchParams(useLocation().search)

    const watch = getSimpleFormWatch(formDisplayData)
    const seatColorHexCode = watch("seatColorHexCode")
    const seatStitchingColorHexCode = watch("seatStitchingColorHexCode")
    const cockpitColorHexCode = watch("cockpitColorHexCode")
    const cockpitStitchingColorHexCode = watch("cockpitStitchingColorHexCode")

    const colorLabel = (
        <>
            Color
            <InteriorColorTile
                seatColor={seatColorHexCode ?? "#000000"}
                seatStitchingColor={seatStitchingColorHexCode}
                cockpitColor={cockpitColorHexCode}
                cockpitStitchingColor={cockpitStitchingColorHexCode}
            />
        </>
    )

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={properties.optionCode}
                    FormElement={getTextFormElement<InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: searchParams.get("optionCode") ?? "",
                    })}
                />
                <EditFormElementWrapper
                    label={"Model Generations"}
                    formDisplayData={formDisplayData}
                    field={properties.modelGenerationKeys}
                    FormElement={getTagsFormElement<InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: createOptions.modelGenerations.map(toOption),
                        value: searchParams.getAll("modelGenerationKeys"),
                        emptyLabel: "-",
                        clearable: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Default Value Status"}
                    formDisplayData={formDisplayData}
                    field={properties.isDefaultValueActive}
                    FormElement={getBoolFormElement<InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: true,
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                    optional: true,
                })}
            />

            <RowGroupWrapper label={colorLabel}>
                <EditFormElementWrapper
                    label={"Seat Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={properties.seatColorHexCode}
                    FormElement={getColorPickerFormElement<InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        hexValue: "#000000",
                        disableGlossy: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Seat Stitching Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={properties.seatStitchingColorHexCode}
                    FormElement={getColorPickerFormElement<InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        hexValue: "#000000",
                        disableGlossy: true,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <EditFormElementWrapper
                    label={"Cockpit Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={properties.cockpitColorHexCode}
                    FormElement={getColorPickerFormElement<InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        hexValue: "#000000",
                        disableGlossy: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Cockpit Stitching Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={properties.cockpitStitchingColorHexCode}
                    FormElement={getColorPickerFormElement<InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        hexValue: "#000000",
                        disableGlossy: true,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <EditFormElementWrapper
                    label={"Interior Color Tag"}
                    formDisplayData={formDisplayData}
                    field={properties.interiorColorTagKey}
                    FormElement={getTagFormElement<InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: createOptions.interiorColorTags.map(toOption),
                        value: createOptions.interiorColorTags[0].key,
                    })}
                />
                <EditFormElementWrapper
                    label={"Custom Color"}
                    formDisplayData={formDisplayData}
                    field={properties.isCustomColor}
                    FormElement={getBoolFormElement<InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: false,
                    })}
                />
            </RowGroupWrapper>

            <RowGroupWrapper label={"Material"}>
                <EditFormElementWrapper
                    label={"Interior Material"}
                    formDisplayData={formDisplayData}
                    field={properties.material}
                    FormElement={getTagFormElement<InteriorCustomizationCreateOptionsDTO, InteriorCustomizationCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: createOptions.interiorMaterials.map(toOption),
                        value: createOptions.interiorMaterials[0].key,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
