import { useSyncState } from "../../../hooks/useSyncState"
import { Box } from "../../layouts/box/Box"
import { SpacedItems } from "../../layouts/spaceditems/SpacedItems"
import { EditLabeledContentWrapper, DefaultFormElementLoading, ViewLabeledContentWrapper, EditProps, FormElement, ViewProps } from "../FormElementWrapper"
import { ExteriorColorTile } from "@finder/ui-kit"
import { PFlex, PFlexItem, PText, PTextFieldWrapper } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { useState } from "react"
import { ChromePicker, ColorResult } from "react-color"
import { FieldValues } from "react-hook-form"
import styled from "styled-components"

export const getColorPickerFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string,
    ColorPickerFormElementViewProps,
    ColorPickerFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: ColorPickerFormElementView,
    Edit: ColorPickerFormElementEdit,
})

interface ColorPickerFormElementViewProps {
    hexValue: string | undefined
    disableGlossy?: boolean
}

export const ColorPickerFormElementView = ({ disableGlossy, hexValue, label }: ViewProps<ColorPickerFormElementViewProps>) => (
    <ViewLabeledContentWrapper label={label}>
        <SpacedItems direction={"row"} itemSpacing={spacing[8]}>
            <PText>{hexValue ?? "-"}</PText>
            {hexValue && <ExteriorColorTile color={hexValue} glossy={!disableGlossy} />}
        </SpacedItems>
    </ViewLabeledContentWrapper>
)

interface ColorPickerFormElementEditProps {
    hexValue: string | undefined

    disableGlossy?: boolean
    onChange?: (hexCode: string) => void

    readOnly?: boolean
}

export const ColorPickerFormElementEdit = <DATA, UPDATE extends FieldValues>({
    disableGlossy,
    field,
    hexValue,
    label,
    onChange,
    readOnly,
    register,
    setValue,
    validationErrors,
}: EditProps<DATA, UPDATE, string, ColorPickerFormElementEditProps>) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const [currentHexValue, setCurrentHexValue] = useSyncState(hexValue, {
        onChange: (hexValue) => {
            onChange && hexValue && onChange(hexValue)
            setValue(field._path, hexValue ?? ("" as any))
        },
    })
    const errorMessage = validationErrors.get(field._path)

    return (
        <>
            <EditLabeledContentWrapper label={label}>
                <PFlex alignItems={"center"}>
                    <PFlexItem width={"three-quarters"}>
                        <PTextFieldWrapper state={errorMessage ? "error" : "none"} message={errorMessage}>
                            <input
                                {...register(field._path)}
                                type={"text"}
                                value={currentHexValue}
                                onChange={(e) => setCurrentHexValue(e.target.value)}
                                readOnly={readOnly}
                            />
                        </PTextFieldWrapper>
                    </PFlexItem>
                    <Box width={spacing[8]} />
                    <PFlexItem>
                        <div onClick={() => setDisplayColorPicker(true)}>
                            <ExteriorColorTile color={currentHexValue ?? "#000000"} glossy={!disableGlossy} />
                        </div>
                    </PFlexItem>
                </PFlex>
            </EditLabeledContentWrapper>
            {!readOnly && displayColorPicker && (
                <Popover>
                    <Cover onClick={() => setDisplayColorPicker(false)} />
                    <ChromePicker disableAlpha={true} color={currentHexValue} onChange={(color: ColorResult) => setCurrentHexValue(color.hex)} />
                </Popover>
            )}
        </>
    )
}

const Popover = styled.div`
    position: absolute;
    z-index: 2;
`

const Cover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`
