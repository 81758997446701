/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EquipmentCustomizationAttributesDTOValue,
    EquipmentCustomizationAttributesDTOValueFromJSON,
    EquipmentCustomizationAttributesDTOValueFromJSONTyped,
    EquipmentCustomizationAttributesDTOValueToJSON,
    MultiSelectDTOString,
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesDTOValue,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesDTOValueFromJSON,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesDTOValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesDTOValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentCustomizationAttributesDTO
 */
export interface EquipmentCustomizationAttributesDTO {
    /**
     * 
     * @type {string}
     * @memberof EquipmentCustomizationAttributesDTO
     */
    optionCode: string;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof EquipmentCustomizationAttributesDTO
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentCustomizationAttributesDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {EquipmentCustomizationAttributesDTOValue}
     * @memberof EquipmentCustomizationAttributesDTO
     */
    defaultValue: EquipmentCustomizationAttributesDTOValue;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesDTOValue>}
     * @memberof EquipmentCustomizationAttributesDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesDTOValue>;
}

export function EquipmentCustomizationAttributesDTOFromJSON(json: any): EquipmentCustomizationAttributesDTO {
    return EquipmentCustomizationAttributesDTOFromJSONTyped(json, false);
}

export function EquipmentCustomizationAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentCustomizationAttributesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': EquipmentCustomizationAttributesDTOValueFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesDTOValueFromJSON)),
    };
}

export function EquipmentCustomizationAttributesDTOToJSON(value?: EquipmentCustomizationAttributesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerations': MultiSelectDTOStringToJSON(value.modelGenerations),
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': EquipmentCustomizationAttributesDTOValueToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesDTOValueToJSON)),
    };
}


