/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelGenerationItemDTO
 */
export interface ModelGenerationItemDTO {
    /**
     * 
     * @type {string}
     * @memberof ModelGenerationItemDTO
     */
    key: string;
    /**
     * 
     * @type {number}
     * @memberof ModelGenerationItemDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof ModelGenerationItemDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGenerationItemDTO
     */
    modelSeries: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGenerationItemDTO
     */
    faceliftOf?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelGenerationItemDTO
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelGenerationItemDTO
     */
    classic: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ModelGenerationItemDTO
     */
    pointOfSalesDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ModelGenerationItemDTO
     */
    endOfProductionDate?: Date;
}

export function ModelGenerationItemDTOFromJSON(json: any): ModelGenerationItemDTO {
    return ModelGenerationItemDTOFromJSONTyped(json, false);
}

export function ModelGenerationItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelGenerationItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'sortIndex': json['sortIndex'],
        'name': json['name'],
        'modelSeries': json['modelSeries'],
        'faceliftOf': !exists(json, 'faceliftOf') ? undefined : json['faceliftOf'],
        'active': json['active'],
        'classic': json['classic'],
        'pointOfSalesDate': !exists(json, 'pointOfSalesDate') ? undefined : (new Date(json['pointOfSalesDate'])),
        'endOfProductionDate': !exists(json, 'endOfProductionDate') ? undefined : (new Date(json['endOfProductionDate'])),
    };
}

export function ModelGenerationItemDTOToJSON(value?: ModelGenerationItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'sortIndex': value.sortIndex,
        'name': value.name,
        'modelSeries': value.modelSeries,
        'faceliftOf': value.faceliftOf,
        'active': value.active,
        'classic': value.classic,
        'pointOfSalesDate': value.pointOfSalesDate === undefined ? undefined : (value.pointOfSalesDate.toISOString().substr(0,10)),
        'endOfProductionDate': value.endOfProductionDate === undefined ? undefined : (value.endOfProductionDate.toISOString().substr(0,10)),
    };
}


