import { propertiesOf, Property } from "../../../../../../common/property"
import { modelGenerationRoutes, roofColorTagRoutes } from "../../../../../../common/routes"
import { FormDisplayData, getEditData } from "../../../../../../components/formelements/FormDisplayData"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { getNumberFormElement } from "../../../../../../components/formelements/numberformelement/NumberFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getTagFormElement } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { getTagsFormElement } from "../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    RoofColorCustomizationAttributesDTO,
    RoofColorCustomizationAttributesDTOValue,
    RoofColorCustomizationAttributesUpdateDTO,
    RoofColorCustomizationAttributesUpdateDTOValue,
} from "../../../../../../generated/pdsapi"
import { useSyncState } from "../../../../../../hooks/useSyncState"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { PButtonPure, PDivider, PHeadline, PText } from "@porsche-design-system/components-react"
import ObjectID from "bson-objectid"
import { FC } from "react"

export const RoofColorCustomizationDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>
> = ({ formDisplayData }) => {
    const roofColorCustomizationProperties = propertiesOf<RoofColorCustomizationAttributesUpdateDTO>()
    const [conditionalValues, setConditionalValues] = useSyncState(formDisplayData.data?.conditionalValues ?? [], {
        onChange: (conditionalValues) =>
            getEditData(formDisplayData)?.setValue(
                "conditionalValues",
                conditionalValues.map((conditionalValue) => ({
                    key: conditionalValue.key,
                    modelYearRange: conditionalValue.modelYearRange,
                    value: {
                        name: conditionalValue.value.name,
                        hexCode: conditionalValue.value.hexCode,
                        roofColorTagKey: conditionalValue.value.tag?.value?.key,
                    },
                }))
            ),
    })

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={roofColorCustomizationProperties.optionCode}
                    FormElement={getTextFormElement<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.optionCode,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.optionCode,
                    })}
                />
                <FormElementWrapper
                    label={"Model Generations"}
                    formDisplayData={formDisplayData}
                    field={roofColorCustomizationProperties.modelGenerationKeys}
                    FormElement={getTagsFormElement<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        emptyLabel: "-",
                        tags: data.modelGenerations.value.map((modelGeneration) => toTagOption(modelGeneration, modelGenerationRoutes.details)),
                    })}
                    getEditProps={(data) => ({
                        emptyLabel: "-",
                        value: data.modelGenerations.value.map((modelGeneration) => modelGeneration.key),
                        options: data.modelGenerations.options.map(toOption),
                        clearable: true,
                    })}
                />
                <FormElementWrapper
                    label={"Default Value Status"}
                    formDisplayData={formDisplayData}
                    field={roofColorCustomizationProperties.isDefaultValueActive}
                    FormElement={getBoolFormElement<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                    getEditProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                />
            </RowGroupWrapper>

            <PDivider />
            <>
                <PHeadline variant={"headline-4"}>Default Value</PHeadline>
                {conditionalValues.length ? (
                    <PText color={"neutral-contrast-medium"}>
                        Not applied in model years:{" "}
                        {conditionalValues
                            .map((conditionalValue) =>
                                conditionalValue.modelYearRange.start !== conditionalValue.modelYearRange.end
                                    ? `${conditionalValue.modelYearRange.start}-${conditionalValue.modelYearRange.end}`
                                    : conditionalValue.modelYearRange.start
                            )
                            .join(", ")}
                    </PText>
                ) : null}
            </>

            <CustomizationValueContent
                key={"defaultValue"}
                formDisplayData={formDisplayData}
                getValue={(attributes) => attributes.defaultValue}
                property={roofColorCustomizationProperties.defaultValue}
            />

            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PDivider /> : null}
            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PHeadline variant={"headline-4"}>Conditional Values</PHeadline> : null}

            {conditionalValues.flatMap((conditionalValue, index) => {
                const property = roofColorCustomizationProperties.conditionalValues._index(index)

                return [
                    <RowGroupWrapper key={`configuration_${index}`} label={"Configuration"}>
                        <FormElementWrapper
                            label={"Key"}
                            formDisplayData={formDisplayData}
                            field={property.key}
                            FormElement={getTextFormElement<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>()}
                            getViewProps={() => ({
                                value: conditionalValue.key,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.key,
                                readOnly: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <RowGroupWrapper key={`model_year_condition_${index}`} label={"Model Year Condition"}>
                        <FormElementWrapper
                            label={"From"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.start}
                            FormElement={getNumberFormElement<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange.start,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange.start,
                            })}
                        />
                        <FormElementWrapper
                            label={"To"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.end}
                            FormElement={getNumberFormElement<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange.end,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange.end,
                            })}
                        />
                    </RowGroupWrapper>,
                    <CustomizationValueContent
                        key={`customization_value_content_${index}`}
                        formDisplayData={formDisplayData}
                        getValue={() => conditionalValue.value}
                        property={property.value}
                    />,
                    formDisplayData.kind === "EDIT" ? (
                        <PButtonPure
                            key={`delete_conditional_value_${index}`}
                            type={"button"}
                            icon={"delete"}
                            onClick={() => {
                                setConditionalValues(conditionalValues.filter((_, i) => i !== index))
                            }}
                        >
                            Delete Conditional Value
                        </PButtonPure>
                    ) : null,
                    formDisplayData.kind === "EDIT" || index !== conditionalValues.length - 1 ? <PDivider key={`divider_${index}`} /> : null,
                ]
            })}
            {formDisplayData.kind === "EDIT" ? (
                <PButtonPure
                    type={"button"}
                    icon={"add"}
                    onClick={() =>
                        setConditionalValues((conditionalValues) => [
                            ...conditionalValues,
                            {
                                key: ObjectID().toHexString(),
                                modelYearRange: { start: 2020, end: 2020 },
                                value: formDisplayData.data.defaultValue,
                            },
                        ])
                    }
                >
                    Add Conditional Value
                </PButtonPure>
            ) : null}
        </SpacedItems>
    )
}

const CustomizationValueContent = ({
    getValue,
    property,
    formDisplayData,
}: {
    key: string
    getValue: (attributes: RoofColorCustomizationAttributesDTO) => RoofColorCustomizationAttributesDTOValue
    property: Property<RoofColorCustomizationAttributesUpdateDTO, RoofColorCustomizationAttributesUpdateDTOValue>
    formDisplayData: FormDisplayData<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>
}) => {
    const attributes = formDisplayData.data
    const value = attributes && getValue(attributes)
    const [hexCode, setHexCode] = useSyncState(value?.hexCode)

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={property.name}
                getViewProps={(data) => ({
                    value: getValue(data).name,
                })}
                getEditProps={(data) => ({
                    value: getValue(data).name,
                    optional: true,
                })}
            />
            <RowGroupWrapper label={"Color"}>
                <FormElementWrapper
                    label={"Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.hexCode}
                    FormElement={getColorPickerFormElement<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={() => ({
                        hexValue: hexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={() => ({
                        hexValue: hexCode,
                        disableGlossy: true,
                        onChange: setHexCode,
                    })}
                />
                <FormElementWrapper
                    label={"Roof Color Tag"}
                    formDisplayData={formDisplayData}
                    field={property.roofColorTagKey}
                    FormElement={getTagFormElement<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => {
                        const tagValue = getValue(data).tag?.value
                        return {
                            tag: tagValue && toTagOption(tagValue, roofColorTagRoutes.details),
                        }
                    }}
                    getEditProps={(data) => {
                        const tag = getValue(data).tag
                        const options = getValue(data).tag?.options ?? []
                        return {
                            value: tag?.value?.key ?? "",
                            options: [
                                {
                                    value: "",
                                    label: "-",
                                },
                                ...options.map((tag) => toOption(tag)),
                            ],
                            optional: true,
                        }
                    }}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
