import { propertiesOf, Property } from "../../../../../../common/property"
import { exteriorColorTagRoutes, modelGenerationRoutes } from "../../../../../../common/routes"
import { FormDisplayData, getEditData } from "../../../../../../components/formelements/FormDisplayData"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { getNumberFormElement } from "../../../../../../components/formelements/numberformelement/NumberFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTagFormElement } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { getTagsFormElement } from "../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    ExteriorColorCustomizationAttributesDTO,
    ExteriorColorCustomizationAttributesDTOValue,
    ExteriorColorCustomizationAttributesUpdateDTO,
    ExteriorColorCustomizationAttributesUpdateDTOValue,
    ExteriorColorType,
} from "../../../../../../generated/pdsapi"
import { useSyncState } from "../../../../../../hooks/useSyncState"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { PButtonPure, PDivider, PHeadline, PText } from "@porsche-design-system/components-react"
import ObjectID from "bson-objectid"
import { FC } from "react"

export const ExteriorColorCustomizationDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>
> = ({ formDisplayData }) => {
    const exteriorColorCustomizationProperties = propertiesOf<ExteriorColorCustomizationAttributesUpdateDTO>()
    const [conditionalValues, setConditionalValues] = useSyncState(formDisplayData.data?.conditionalValues ?? [], {
        onChange: (conditionalValues) =>
            getEditData(formDisplayData)?.setValue(
                "conditionalValues",
                conditionalValues.map((conditionalValue) => ({
                    key: conditionalValue.key,
                    modelYearRange: conditionalValue.modelYearRange,
                    value: {
                        name: conditionalValue.value.name,
                        hexCode: conditionalValue.value.hexCode,
                        isCustomColor: conditionalValue.value.isCustomColor,
                        exteriorColorTagKey: conditionalValue.value.tag.value?.key,
                        exteriorColorType: conditionalValue.value.exteriorColorType.value?.key ?? ("" as ExteriorColorType),
                    },
                }))
            ),
    })

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={exteriorColorCustomizationProperties.optionCode}
                    FormElement={getTextFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.optionCode,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.optionCode,
                    })}
                />
                <FormElementWrapper
                    label={"Model Generations"}
                    formDisplayData={formDisplayData}
                    field={exteriorColorCustomizationProperties.modelGenerationKeys}
                    FormElement={getTagsFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        emptyLabel: "-",
                        tags: data.modelGenerations.value.map((modelGeneration) => toTagOption(modelGeneration, modelGenerationRoutes.details)),
                    })}
                    getEditProps={(data) => ({
                        emptyLabel: "-",
                        value: data.modelGenerations.value.map((modelGeneration) => modelGeneration.key),
                        options: data.modelGenerations.options.map(toOption),
                        clearable: true,
                    })}
                />
                <FormElementWrapper
                    label={"Default Value Status"}
                    formDisplayData={formDisplayData}
                    field={exteriorColorCustomizationProperties.isDefaultValueActive}
                    FormElement={getBoolFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                    getEditProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                />
            </RowGroupWrapper>

            <PDivider />
            <>
                <PHeadline variant={"headline-4"}>Default Value</PHeadline>
                {conditionalValues.length ? (
                    <PText color={"neutral-contrast-medium"}>
                        Not applied in model years:{" "}
                        {conditionalValues
                            .map((conditionalValue) =>
                                conditionalValue.modelYearRange.start !== conditionalValue.modelYearRange.end
                                    ? `${conditionalValue.modelYearRange.start}-${conditionalValue.modelYearRange.end}`
                                    : conditionalValue.modelYearRange.start
                            )
                            .join(", ")}
                    </PText>
                ) : null}
            </>

            <CustomizationValueContent
                key={"defaultValue"}
                formDisplayData={formDisplayData}
                getValue={(attributes) => attributes.defaultValue}
                property={exteriorColorCustomizationProperties.defaultValue}
            />

            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PDivider /> : null}
            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PHeadline variant={"headline-4"}>Conditional Values</PHeadline> : null}

            {conditionalValues.flatMap((conditionalValue, index) => {
                const property = exteriorColorCustomizationProperties.conditionalValues._index(index)

                return [
                    <RowGroupWrapper key={`configuration_${index}`} label={"Configuration"}>
                        <FormElementWrapper
                            label={"Key"}
                            formDisplayData={formDisplayData}
                            field={property.key}
                            FormElement={getTextFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                            getViewProps={() => ({
                                value: conditionalValue.key,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.key,
                                readOnly: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <RowGroupWrapper key={`model_year_condition_${index}`} label={"Model Year Condition"}>
                        <FormElementWrapper
                            label={"From"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.start}
                            FormElement={getNumberFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange.start,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange.start,
                            })}
                        />
                        <FormElementWrapper
                            label={"To"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.end}
                            FormElement={getNumberFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange.end,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange.end,
                            })}
                        />
                    </RowGroupWrapper>,
                    <CustomizationValueContent
                        key={`customization_value_content_${index}`}
                        formDisplayData={formDisplayData}
                        getValue={() => conditionalValue.value}
                        property={property.value}
                    />,
                    formDisplayData.kind === "EDIT" ? (
                        <PButtonPure
                            key={`delete_conditional_value_${index}`}
                            type={"button"}
                            icon={"delete"}
                            onClick={() => {
                                setConditionalValues(conditionalValues.filter((_, i) => i !== index))
                            }}
                        >
                            Delete Conditional Value
                        </PButtonPure>
                    ) : null,
                    formDisplayData.kind === "EDIT" || index !== conditionalValues.length - 1 ? <PDivider key={`divider_${index}`} /> : null,
                ]
            })}
            {formDisplayData.kind === "EDIT" ? (
                <PButtonPure
                    type={"button"}
                    icon={"add"}
                    onClick={() =>
                        setConditionalValues((conditionalValues) => [
                            ...conditionalValues,
                            {
                                key: ObjectID().toHexString(),
                                modelYearRange: { start: 2020, end: 2020 },
                                value: formDisplayData.data.defaultValue,
                            },
                        ])
                    }
                >
                    Add Conditional Value
                </PButtonPure>
            ) : null}
        </SpacedItems>
    )
}

const CustomizationValueContent = ({
    getValue,
    property,
    formDisplayData,
}: {
    key: string
    getValue: (attributes: ExteriorColorCustomizationAttributesDTO) => ExteriorColorCustomizationAttributesDTOValue
    property: Property<ExteriorColorCustomizationAttributesUpdateDTO, ExteriorColorCustomizationAttributesUpdateDTOValue>
    formDisplayData: FormDisplayData<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>
}) => {
    const attributes = formDisplayData.data
    const value = attributes && getValue(attributes)
    const [hexCode, setHexCode] = useSyncState(value?.hexCode)

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={property.name}
                getViewProps={(data) => ({
                    value: getValue(data).name,
                })}
                getEditProps={(data) => ({
                    value: getValue(data).name,
                    optional: true,
                })}
            />
            <RowGroupWrapper label={"Color"}>
                <FormElementWrapper
                    label={"Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.hexCode}
                    FormElement={getColorPickerFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={() => ({
                        hexValue: hexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={() => ({
                        hexValue: hexCode,
                        disableGlossy: true,
                        onChange: setHexCode,
                    })}
                />
                <FormElementWrapper
                    label={"Exterior Color Tag"}
                    formDisplayData={formDisplayData}
                    field={property.exteriorColorTagKey}
                    FormElement={getTagFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => {
                        const tag = getValue(data).tag.value
                        return {
                            tag: tag && toTagOption(tag, exteriorColorTagRoutes.details),
                        }
                    }}
                    getEditProps={(data) => ({
                        value: getValue(data).tag.value?.key ?? "",
                        options: [
                            {
                                value: "",
                                label: "-",
                            },
                            ...getValue(data).tag.options.map((tag) => toOption(tag)),
                        ],
                        optional: true,
                    })}
                />
                <FormElementWrapper
                    label={"Exterior Color Type"}
                    formDisplayData={formDisplayData}
                    field={property.exteriorColorType}
                    FormElement={getSelectTextFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: getValue(data).exteriorColorType.value?.label ?? "-",
                    })}
                    getEditProps={(data) => ({
                        value: getValue(data).exteriorColorType.value?.key ?? "",
                        options: [{ value: "", label: "-" }, ...getValue(data).exteriorColorType.options.map(toOption)],
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <FormElementWrapper
                    label={"Custom Color"}
                    formDisplayData={formDisplayData}
                    field={property.isCustomColor}
                    FormElement={getBoolFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: getValue(data).isCustomColor,
                    })}
                    getEditProps={(data) => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: getValue(data).isCustomColor,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
