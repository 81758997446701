import { DefaultCardViewContentWrapper } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { CreateOptionResponseDTO, ExteriorColorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { exteriorColorApi } from "../../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypeExteriorColorCreateOptionCard } from "./option/OrderTypeExteriorColorCreateOptionCard"

export const OrderTypeExteriorColorProductCreateContainerConfig: ProductCreateContainerConfig<ExteriorColorCreateRequestDTO, unknown, CreateOptionResponseDTO> =
    {
        headline: "Create new ExteriorColor",

        create: (item) =>
            exteriorColorApi.create.create({
                exteriorColorCreateRequestDTO: item,
            }),
        getCreateOptions: async () => ({}),

        Content: (props) => (
            <DefaultCardViewContentWrapper>
                <OrderTypeExteriorColorCreateOptionCard {...props} />
            </DefaultCardViewContentWrapper>
        ),
    }

export const OrderTypeExteriorColorCreateContainer = getProductCreateContainer(OrderTypeExteriorColorProductCreateContainerConfig)
