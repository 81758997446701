/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExteriorColorOptionDTOBaseLocalization,
    ExteriorColorOptionDTOBaseLocalizationFromJSON,
    ExteriorColorOptionDTOBaseLocalizationFromJSONTyped,
    ExteriorColorOptionDTOBaseLocalizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExteriorColorOptionDTO
 */
export interface ExteriorColorOptionDTO {
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorOptionDTO
     */
    optionCode: string;
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorOptionDTO
     */
    optionType?: string;
    /**
     * 
     * @type {number}
     * @memberof ExteriorColorOptionDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {ExteriorColorOptionDTOBaseLocalization}
     * @memberof ExteriorColorOptionDTO
     */
    name: ExteriorColorOptionDTOBaseLocalization;
}

export function ExteriorColorOptionDTOFromJSON(json: any): ExteriorColorOptionDTO {
    return ExteriorColorOptionDTOFromJSONTyped(json, false);
}

export function ExteriorColorOptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorOptionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'optionType': !exists(json, 'optionType') ? undefined : json['optionType'],
        'sortIndex': json['sortIndex'],
        'name': ExteriorColorOptionDTOBaseLocalizationFromJSON(json['name']),
    };
}

export function ExteriorColorOptionDTOToJSON(value?: ExteriorColorOptionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'optionType': value.optionType,
        'sortIndex': value.sortIndex,
        'name': ExteriorColorOptionDTOBaseLocalizationToJSON(value.name),
    };
}


