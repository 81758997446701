/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EquipmentCustomizationAttributesUpdateDTOValue,
    EquipmentCustomizationAttributesUpdateDTOValueFromJSON,
    EquipmentCustomizationAttributesUpdateDTOValueFromJSONTyped,
    EquipmentCustomizationAttributesUpdateDTOValueToJSON,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesUpdateDTOValue,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesUpdateDTOValueFromJSON,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesUpdateDTOValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesUpdateDTOValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentCustomizationAttributesUpdateDTO
 */
export interface EquipmentCustomizationAttributesUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof EquipmentCustomizationAttributesUpdateDTO
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EquipmentCustomizationAttributesUpdateDTO
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentCustomizationAttributesUpdateDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {EquipmentCustomizationAttributesUpdateDTOValue}
     * @memberof EquipmentCustomizationAttributesUpdateDTO
     */
    defaultValue: EquipmentCustomizationAttributesUpdateDTOValue;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesUpdateDTOValue>}
     * @memberof EquipmentCustomizationAttributesUpdateDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesUpdateDTOValue>;
}

export function EquipmentCustomizationAttributesUpdateDTOFromJSON(json: any): EquipmentCustomizationAttributesUpdateDTO {
    return EquipmentCustomizationAttributesUpdateDTOFromJSONTyped(json, false);
}

export function EquipmentCustomizationAttributesUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentCustomizationAttributesUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': EquipmentCustomizationAttributesUpdateDTOValueFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesUpdateDTOValueFromJSON)),
    };
}

export function EquipmentCustomizationAttributesUpdateDTOToJSON(value?: EquipmentCustomizationAttributesUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': EquipmentCustomizationAttributesUpdateDTOValueToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOEquipmentCustomizationAttributesUpdateDTOValueToJSON)),
    };
}


