import { ViewState } from "../../../../../../common/ViewState"
import { propertiesOf } from "../../../../../../common/property"
import { booleanConverterGroup, useURLSearchParamState } from "../../../../../../common/useURLSearchParamState"
import { getEditData, NotLoadingFormDisplayData } from "../../../../../../components/formelements/FormDisplayData"
import { NotLoadingFormElementWrapper } from "../../../../../../components/formelements/NotLoadingFormElementWrapper"
import { getMultiSelectTextFormElement } from "../../../../../../components/formelements/multiselecttextformelement/MultiSelectTextFormElement"
import { Box } from "../../../../../../components/layouts/box/Box"
import { rowGroupsSpacing } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { ColumnBuilder, Table } from "../../../../../../components/layouts/table/Table"
import {
    MarketplaceSpecificOrderTypeTechnicalDataAttributeDTO,
    MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue,
    OrderTypeTechnicalDataAttributeUpdateDTO,
    TechnicalDataAttributeValueType,
} from "../../../../../../generated/pdsapi"
import { useSyncState } from "../../../../../../hooks/useSyncState"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { useOrderTypeTechnicalDataTabContext } from "../OrderTypeTechnicalDataTabContext"
import { TechnicalDataAttributeDisclaimer } from "./disclaimer/TechnicalDataAttributeDisclaimer"
import { OrderTypeTechnicalDataValueTableFormElement } from "./technicaldatavaluetableformelement/OrderTypeTechnicalDataValueTableFormElement"
import { PButtonPure } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, useCallback, useEffect, useMemo } from "react"

export interface OrderTypeTechnicalDataAttributeTableItem {
    specificEquipmentRelationsIndex: number | undefined
    optionCodes: string[]
    values: Record<string, MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue>
    isGlobalValue: boolean
}

export const OrderTypeTechnicalDataAttributeCardContent: FC<
    ProductDetailsCardContentProps<MarketplaceSpecificOrderTypeTechnicalDataAttributeDTO, OrderTypeTechnicalDataAttributeUpdateDTO>
> = ({ formDisplayData, urlParamsPrefix, setLabel }) => {
    const ggIdSpecificAttribute = formDisplayData.data
    const { possibleOptionCodes } = useOrderTypeTechnicalDataTabContext()

    const [specificEquipmentRelations, setSpecificEquipmentRelations] = useSyncState(ggIdSpecificAttribute?.specificEquipmentRelations)
    const [disclaimerOpen, setDisclaimerOpen] = useURLSearchParamState(`${urlParamsPrefix}.disclaimerOpen`, false, booleanConverterGroup.required)

    useEffect(() => {
        if (ggIdSpecificAttribute) {
            setLabel(ggIdSpecificAttribute.attributeName)
        }
    }, [ggIdSpecificAttribute, setLabel])

    const columnDefinitions = ggIdSpecificAttribute?.columnDefinitions
    const columns: ColumnBuilder<NotLoadingFormDisplayData<OrderTypeTechnicalDataAttributeTableItem, OrderTypeTechnicalDataAttributeUpdateDTO>, never>[] =
        useMemo(() => {
            if (!columnDefinitions) {
                return []
            }

            const properties = propertiesOf<OrderTypeTechnicalDataAttributeUpdateDTO>()
            return [
                {
                    grow: 5,
                    headerCellProps: {
                        content: "Option Codes",
                    },
                    buildCellContent: (formDisplayData) => (
                        <NotLoadingFormElementWrapper
                            label={undefined}
                            formDisplayData={
                                formDisplayData.data.isGlobalValue
                                    ? {
                                          kind: "VIEW",
                                          data: formDisplayData.data,
                                      }
                                    : formDisplayData
                            }
                            field={properties.equipmentRelations._index(formDisplayData.data.specificEquipmentRelationsIndex ?? -1).equipmentCodes}
                            FormElement={getMultiSelectTextFormElement<OrderTypeTechnicalDataAttributeTableItem, OrderTypeTechnicalDataAttributeUpdateDTO>()}
                            getViewProps={() => ({
                                emptyLabel: "-",
                                showAsHint: formDisplayData.data.isGlobalValue,
                                texts: formDisplayData.data.optionCodes,
                            })}
                            getEditProps={() => ({
                                emptyLabel: "-",
                                value: formDisplayData.data.optionCodes,
                                options: possibleOptionCodes.map((optionCode) => ({
                                    label: optionCode,
                                    value: optionCode,
                                })),
                                clearable: true,
                                optional: true,
                            })}
                        />
                    ),
                },
                ...columnDefinitions.map((columnDefinition) => ({
                    grow: columnDefinition.type === TechnicalDataAttributeValueType.INT_RANGE ? 3 : 2,
                    headerCellProps: {
                        content: columnDefinition.label,
                    },
                    buildCellContent: (
                        formDisplayData: NotLoadingFormDisplayData<OrderTypeTechnicalDataAttributeTableItem, OrderTypeTechnicalDataAttributeUpdateDTO>
                    ) => (
                        <OrderTypeTechnicalDataValueTableFormElement
                            formDisplayData={
                                formDisplayData.data.isGlobalValue
                                    ? {
                                          kind: "VIEW",
                                          data: formDisplayData.data,
                                      }
                                    : formDisplayData
                            }
                            field={properties.equipmentRelations._index(formDisplayData.data.specificEquipmentRelationsIndex ?? -1)}
                            columnDefinition={columnDefinition}
                        />
                    ),
                })),
                ...(formDisplayData.kind === "EDIT"
                    ? [
                          {
                              grow: 2,
                              headerCellProps: {
                                  content: "",
                              },
                              buildCellContent: (
                                  formDisplayData: NotLoadingFormDisplayData<OrderTypeTechnicalDataAttributeTableItem, OrderTypeTechnicalDataAttributeUpdateDTO>
                              ) =>
                                  formDisplayData.data.isGlobalValue ? (
                                      ""
                                  ) : (
                                      <PButtonPure
                                          onClick={() => {
                                              const edit = getEditData(formDisplayData)!
                                              edit.setValue(
                                                  "equipmentRelations",
                                                  edit.watch("equipmentRelations").filter((_, i) => i !== formDisplayData.data.specificEquipmentRelationsIndex)
                                              )
                                              setSpecificEquipmentRelations((equipmentRelations) =>
                                                  equipmentRelations?.filter((_, i) => i !== formDisplayData.data.specificEquipmentRelationsIndex)
                                              )
                                          }}
                                          icon={"delete"}
                                          disabled={formDisplayData.data.isGlobalValue}
                                          weight={"semibold"}
                                          type={"button"}
                                      >
                                          Remove
                                      </PButtonPure>
                                  ),
                          },
                      ]
                    : []),
            ]
        }, [columnDefinitions, formDisplayData.kind, possibleOptionCodes, setSpecificEquipmentRelations])

    const tableItems: NotLoadingFormDisplayData<OrderTypeTechnicalDataAttributeTableItem, OrderTypeTechnicalDataAttributeUpdateDTO>[] =
        formDisplayData.kind === "LOADING"
            ? []
            : [
                  ...formDisplayData.data.globalEquipmentRelations.map((globalEquipmentRelation) => ({
                      ...formDisplayData,
                      data: {
                          specificEquipmentRelationsIndex: undefined,
                          optionCodes: globalEquipmentRelation.equipmentCodes,
                          values: Object.fromEntries(Object.entries(globalEquipmentRelation.values).map(([field, value]) => [field, { specificValue: value }])),
                          isGlobalValue: true,
                      },
                  })),
                  ...(specificEquipmentRelations?.map((specificEquipmentRelation, index) => ({
                      ...formDisplayData,
                      data: {
                          specificEquipmentRelationsIndex: index,
                          optionCodes: specificEquipmentRelation.equipmentCodes,
                          values: specificEquipmentRelation.values,
                          isGlobalValue: false,
                      },
                  })) ?? []),
              ]

    const handleAddRowItemButtonClick = () => {
        const edit = getEditData(formDisplayData)!
        setSpecificEquipmentRelations((specificEquipmentRelations) => [
            ...(specificEquipmentRelations ?? []),
            {
                equipmentCodes: [],
                values: {},
            },
        ])
        edit.setValue("equipmentRelations", [
            ...(edit.watch("equipmentRelations") ?? []),
            {
                equipmentCodes: [],
                values: {},
            },
        ])
    }

    const getItemKey = useCallback(
        (tableItem: NotLoadingFormDisplayData<OrderTypeTechnicalDataAttributeTableItem, OrderTypeTechnicalDataAttributeUpdateDTO>) =>
            tableItem.data.optionCodes.join(", "),
        []
    )

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <SpacedItems direction={"column"} lineSpacing={spacing[16]}>
                <Table
                    viewState={formDisplayData.kind === "LOADING" ? ViewState.LOADING : ViewState.CONTENT}
                    items={tableItems ?? []}
                    columns={columns}
                    getItemKey={getItemKey}
                />
                {formDisplayData.kind === "EDIT" && (
                    <PButtonPure onClick={handleAddRowItemButtonClick} icon={"plus"} weight={"semibold"} type={"button"}>
                        Add equipment related value
                    </PButtonPure>
                )}
            </SpacedItems>

            {formDisplayData.data && Object.keys(formDisplayData.data.disclaimer).length > 0 && (
                <div>
                    <PButtonPure
                        weight={"semibold"}
                        key={"disclaimerButton"}
                        icon={disclaimerOpen ? "arrow-head-up" : "arrow-head-down"}
                        onClick={() => setDisclaimerOpen((disclaimerOpen) => !disclaimerOpen)}
                        type={"button"}
                    >
                        Disclaimer
                    </PButtonPure>

                    <Box height={spacing[16]} />

                    {disclaimerOpen && <TechnicalDataAttributeDisclaimer disclaimer={formDisplayData.data.disclaimer} />}
                </div>
            )}
        </SpacedItems>
    )
}
