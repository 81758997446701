/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    DriveTrain,
    DriveTrainFromJSON,
    DriveTrainFromJSONTyped,
    DriveTrainToJSON,
    EngineType,
    EngineTypeFromJSON,
    EngineTypeFromJSONTyped,
    EngineTypeToJSON,
    NumberOfSeats,
    NumberOfSeatsFromJSON,
    NumberOfSeatsFromJSONTyped,
    NumberOfSeatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderTypeCreateRequestDTO
 */
export interface OrderTypeCreateRequestDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeCreateRequestDTO
     */
    codes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderTypeCreateRequestDTO
     */
    modelYear: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeCreateRequestDTO
     */
    modelGenerationKey: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeCreateRequestDTO
     */
    derivativeKey: string;
    /**
     * 
     * @type {DriveTrain}
     * @memberof OrderTypeCreateRequestDTO
     */
    driveTrain: DriveTrain;
    /**
     * 
     * @type {EngineType}
     * @memberof OrderTypeCreateRequestDTO
     */
    engineType: EngineType;
    /**
     * 
     * @type {NumberOfSeats}
     * @memberof OrderTypeCreateRequestDTO
     */
    numberOfSeats: NumberOfSeats;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OrderTypeCreateRequestDTO
     */
    manualName: BaseNameDTO;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeCreateRequestDTO
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeCreateRequestDTO
     */
    special: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeCreateRequestDTO
     */
    limited: boolean;
}

export function OrderTypeCreateRequestDTOFromJSON(json: any): OrderTypeCreateRequestDTO {
    return OrderTypeCreateRequestDTOFromJSONTyped(json, false);
}

export function OrderTypeCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeCreateRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codes': json['codes'],
        'modelYear': json['modelYear'],
        'modelGenerationKey': json['modelGenerationKey'],
        'derivativeKey': json['derivativeKey'],
        'driveTrain': DriveTrainFromJSON(json['driveTrain']),
        'engineType': EngineTypeFromJSON(json['engineType']),
        'numberOfSeats': NumberOfSeatsFromJSON(json['numberOfSeats']),
        'manualName': BaseNameDTOFromJSON(json['manualName']),
        'active': json['active'],
        'special': json['special'],
        'limited': json['limited'],
    };
}

export function OrderTypeCreateRequestDTOToJSON(value?: OrderTypeCreateRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codes': value.codes,
        'modelYear': value.modelYear,
        'modelGenerationKey': value.modelGenerationKey,
        'derivativeKey': value.derivativeKey,
        'driveTrain': DriveTrainToJSON(value.driveTrain),
        'engineType': EngineTypeToJSON(value.engineType),
        'numberOfSeats': NumberOfSeatsToJSON(value.numberOfSeats),
        'manualName': BaseNameDTOToJSON(value.manualName),
        'active': value.active,
        'special': value.special,
        'limited': value.limited,
    };
}


