import { orderTypeRoutes } from "../../../../common/routes"
import { booleanConverterGroup, getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { OrderTypeFilterOptionsDTO, OrderTypeItemDTO, OrderTypeSortField, SortDirection } from "../../../../generated/pdsapi"
import { orderTypeApi, OrderTypeFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { OrderTypeListColumnsConfig } from "./columns/OrderTypeListColumns"
import { OrderTypeListFilter } from "./filter/OrderTypeListFilter"
import { OrderTypeListFilterTagsConfig } from "./filter/OrderTypeListFilterTagsConfig"

export const OrderTypeProductListContainerConfig: ProductListContainerConfig<OrderTypeItemDTO, OrderTypeFilter, OrderTypeFilterOptionsDTO, OrderTypeSortField> =
    {
        listName: "Order Types",
        createItemLabel: "Add new Order Type",

        buildDetailsPath: (orderType) => orderTypeRoutes.details(orderType.key),

        defaultFilter: {},
        filterUrlParamsConverter: getObjectURLParamConverter<OrderTypeFilter>({
            modelSeriesKeys: stringConverterGroup.optionalArray,
            modelGenerationKeys: stringConverterGroup.optionalArray,
            derivativeKeys: stringConverterGroup.optionalArray,
            active: booleanConverterGroup.optional,
        }),
        defaultSorting: {
            sortField: OrderTypeSortField.SORT_INDEX,
            sortDirection: SortDirection.ASC,
        },
        sortingUrlParamsConverter: getObjectURLParamConverter({
            sortField: getEnumConverterGroup(OrderTypeSortField).required,
            sortDirection: getEnumConverterGroup(SortDirection).required,
        }),
        fetchPage: orderTypeApi.list.getPage,
        fetchFilterOptions: orderTypeApi.list.getFilterOptions,

        defaultShowsFilter: false,
        Filter: OrderTypeListFilter,
        filterTagsConfig: OrderTypeListFilterTagsConfig,

        columnsConfig: OrderTypeListColumnsConfig,
    }

export const OrderTypeListContainer = getProductListContainer(OrderTypeProductListContainerConfig)
