import { propertiesOf } from "../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../components/formelements/EditFormElementWrapper"
import { getSimpleFormWatch } from "../../../../../components/formelements/FormDisplayData"
import { getBoolFormElement } from "../../../../../components/formelements/boolformelement/BoolFormElement"
import { getDateFormElement } from "../../../../../components/formelements/dateformelement/DateFormElement"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { getTagFormElement } from "../../../../../components/formelements/tagformelement/TagFormElement"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { ModelGenerationCreateOptionsDTO, ModelGenerationCreateRequestDTO } from "../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { FC } from "react"
import { useLocation } from "react-router-dom"

export const ModelGenerationCreateAttributesCard: FC<ProductCreateContentProps<ModelGenerationCreateRequestDTO, ModelGenerationCreateOptionsDTO>> = ({
    formDisplayData,
}) => {
    const properties = propertiesOf<ModelGenerationCreateRequestDTO>()
    const searchParams = new URLSearchParams(useLocation().search)
    const watch = getSimpleFormWatch(formDisplayData)
    const modelSeriesKey = watch("modelSeriesKey")

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Code"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<ModelGenerationCreateOptionsDTO, ModelGenerationCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: searchParams.get(properties.key._path) ?? "",
                    })}
                />

                <EditFormElementWrapper
                    label={"Model Series"}
                    formDisplayData={formDisplayData}
                    field={properties.modelSeriesKey}
                    FormElement={getTagFormElement<ModelGenerationCreateOptionsDTO, ModelGenerationCreateRequestDTO>()}
                    getEditProps={(data) => ({
                        value: searchParams.get(properties.modelSeriesKey._path) ?? data.modelSeries[0].key,
                        options: data.modelSeries.map(toOption),
                    })}
                />

                <EditFormElementWrapper
                    label={"Facelift Of"}
                    formDisplayData={formDisplayData}
                    field={properties.faceliftOf}
                    FormElement={getTagFormElement<ModelGenerationCreateOptionsDTO, ModelGenerationCreateRequestDTO>()}
                    getEditProps={(data) => ({
                        value: searchParams.get(properties.faceliftOf._path) ?? "",
                        options: [
                            {
                                value: "",
                                label: "-",
                            },
                            ...data.modelSeriesRelatedModelGenerations.filter((modelGeneration) => modelGeneration.relatedKey === modelSeriesKey).map(toOption),
                        ],
                        optional: true,
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                })}
            />

            <RowGroupWrapper label={"Status"}>
                <EditFormElementWrapper
                    label={"Active"}
                    formDisplayData={formDisplayData}
                    field={properties.active}
                    FormElement={getBoolFormElement<ModelGenerationCreateOptionsDTO, ModelGenerationCreateRequestDTO>()}
                    getEditProps={() => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Classic"}
                    formDisplayData={formDisplayData}
                    field={properties.classic}
                    FormElement={getBoolFormElement<ModelGenerationCreateOptionsDTO, ModelGenerationCreateRequestDTO>()}
                    getEditProps={() => ({
                        falseMessage: "No",
                        trueMessage: "Yes",
                        value: false,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper label={"Production Period"}>
                <EditFormElementWrapper
                    label={"Point of Sales Date"}
                    formDisplayData={formDisplayData}
                    field={properties.pointOfSalesDate}
                    FormElement={getDateFormElement<ModelGenerationCreateOptionsDTO, ModelGenerationCreateRequestDTO>()}
                    getEditProps={() => ({
                        optional: true,
                        value: undefined,
                    })}
                />
                <EditFormElementWrapper
                    label={"End of Production Date"}
                    formDisplayData={formDisplayData}
                    field={properties.endOfProductionDate}
                    FormElement={getDateFormElement<ModelGenerationCreateOptionsDTO, ModelGenerationCreateRequestDTO>()}
                    getEditProps={() => ({
                        optional: true,
                        value: undefined,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
