/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InteriorCustomizationAttributesUpdateDTOValue,
    InteriorCustomizationAttributesUpdateDTOValueFromJSON,
    InteriorCustomizationAttributesUpdateDTOValueFromJSONTyped,
    InteriorCustomizationAttributesUpdateDTOValueToJSON,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValue,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValueFromJSON,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorCustomizationAttributesUpdateDTO
 */
export interface InteriorCustomizationAttributesUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesUpdateDTO
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InteriorCustomizationAttributesUpdateDTO
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof InteriorCustomizationAttributesUpdateDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {InteriorCustomizationAttributesUpdateDTOValue}
     * @memberof InteriorCustomizationAttributesUpdateDTO
     */
    defaultValue: InteriorCustomizationAttributesUpdateDTOValue;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValue>}
     * @memberof InteriorCustomizationAttributesUpdateDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValue>;
}

export function InteriorCustomizationAttributesUpdateDTOFromJSON(json: any): InteriorCustomizationAttributesUpdateDTO {
    return InteriorCustomizationAttributesUpdateDTOFromJSONTyped(json, false);
}

export function InteriorCustomizationAttributesUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationAttributesUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': InteriorCustomizationAttributesUpdateDTOValueFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValueFromJSON)),
    };
}

export function InteriorCustomizationAttributesUpdateDTOToJSON(value?: InteriorCustomizationAttributesUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': InteriorCustomizationAttributesUpdateDTOValueToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValueToJSON)),
    };
}


