/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TechnicalDataAttribute {
    ENGINE_DISPLACEMENT = 'ENGINE_DISPLACEMENT',
    ENGINE_NUMBER_OF_CYLINDERS = 'ENGINE_NUMBER_OF_CYLINDERS',
    ENGINE_MAX_TORQUE_COMBINED = 'ENGINE_MAX_TORQUE_COMBINED',
    ENGINE_MAX_TORQUE_COMBUSTION_ENGINE = 'ENGINE_MAX_TORQUE_COMBUSTION_ENGINE',
    ENGINE_MAX_TORQUE_COMBUSTION_ENGINE_WITH_OVERBOOST = 'ENGINE_MAX_TORQUE_COMBUSTION_ENGINE_WITH_OVERBOOST',
    ENGINE_MAX_TORQUE_ELECTRIC_ENGINE = 'ENGINE_MAX_TORQUE_ELECTRIC_ENGINE',
    ENGINE_MAX_TORQUE_LAUNCH_CONTROL = 'ENGINE_MAX_TORQUE_LAUNCH_CONTROL',
    ENGINE_MAX_TORQUE_AT_RPM_COMBINED_VALUE = 'ENGINE_MAX_TORQUE_AT_RPM_COMBINED_VALUE',
    ENGINE_MAX_TORQUE_AT_RPM_COMBINED_RANGE = 'ENGINE_MAX_TORQUE_AT_RPM_COMBINED_RANGE',
    ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_VALUE = 'ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_VALUE',
    ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_RANGE = 'ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_RANGE',
    ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_WITH_OVERBOOST_VALUE = 'ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_WITH_OVERBOOST_VALUE',
    ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_WITH_OVERBOOST_RANGE = 'ENGINE_MAX_TORQUE_AT_RPM_COMBUSTION_ENGINE_WITH_OVERBOOST_RANGE',
    ENGINE_MAX_TORQUE_AT_RPM_ELECTRIC_ENGINE_VALUE = 'ENGINE_MAX_TORQUE_AT_RPM_ELECTRIC_ENGINE_VALUE',
    ENGINE_MAX_TORQUE_AT_RPM_ELECTRIC_ENGINE_RANGE = 'ENGINE_MAX_TORQUE_AT_RPM_ELECTRIC_ENGINE_RANGE',
    ENGINE_POWER_COMBINED_KILOWATTS = 'ENGINE_POWER_COMBINED_KILOWATTS',
    ENGINE_POWER_COMBINED_HORSEPOWER = 'ENGINE_POWER_COMBINED_HORSEPOWER',
    ENGINE_POWER_COMBINED_BRAKE_HORSEPOWER = 'ENGINE_POWER_COMBINED_BRAKE_HORSEPOWER',
    ENGINE_POWER_COMBUSTION_ENGINE_KILOWATTS = 'ENGINE_POWER_COMBUSTION_ENGINE_KILOWATTS',
    ENGINE_POWER_COMBUSTION_ENGINE_HORSEPOWER = 'ENGINE_POWER_COMBUSTION_ENGINE_HORSEPOWER',
    ENGINE_POWER_COMBUSTION_ENGINE_BRAKE_HORSEPOWER = 'ENGINE_POWER_COMBUSTION_ENGINE_BRAKE_HORSEPOWER',
    ENGINE_POWER_ELECTRIC_ENGINE_KILOWATTS = 'ENGINE_POWER_ELECTRIC_ENGINE_KILOWATTS',
    ENGINE_POWER_ELECTRIC_ENGINE_HORSEPOWER = 'ENGINE_POWER_ELECTRIC_ENGINE_HORSEPOWER',
    ENGINE_POWER_ELECTRIC_ENGINE_BRAKE_HORSEPOWER = 'ENGINE_POWER_ELECTRIC_ENGINE_BRAKE_HORSEPOWER',
    ENGINE_POWER_UP_TO_KILOWATTS = 'ENGINE_POWER_UP_TO_KILOWATTS',
    ENGINE_POWER_UP_TO_HORSEPOWER = 'ENGINE_POWER_UP_TO_HORSEPOWER',
    ENGINE_POWER_UP_TO_BRAKE_HORSEPOWER = 'ENGINE_POWER_UP_TO_BRAKE_HORSEPOWER',
    ENGINE_POWER_OVERBOOST_LAUNCH_CONTROL_UP_TO_KILOWATTS = 'ENGINE_POWER_OVERBOOST_LAUNCH_CONTROL_UP_TO_KILOWATTS',
    ENGINE_POWER_OVERBOOST_LAUNCH_CONTROL_UP_TO_HORSEPOWER = 'ENGINE_POWER_OVERBOOST_LAUNCH_CONTROL_UP_TO_HORSEPOWER',
    ENGINE_POWER_OVERBOOST_LAUNCH_CONTROL_UP_TO_BRAKE_HORSEPOWER = 'ENGINE_POWER_OVERBOOST_LAUNCH_CONTROL_UP_TO_BRAKE_HORSEPOWER',
    ENGINE_MAX_POWER_AT_RPM_COMBINED_VALUE = 'ENGINE_MAX_POWER_AT_RPM_COMBINED_VALUE',
    ENGINE_MAX_POWER_AT_RPM_COMBINED_RANGE = 'ENGINE_MAX_POWER_AT_RPM_COMBINED_RANGE',
    ENGINE_MAX_POWER_AT_RPM_COMBUSTION_ENGINE_VALUE = 'ENGINE_MAX_POWER_AT_RPM_COMBUSTION_ENGINE_VALUE',
    ENGINE_MAX_POWER_AT_RPM_COMBUSTION_ENGINE_RANGE = 'ENGINE_MAX_POWER_AT_RPM_COMBUSTION_ENGINE_RANGE',
    ENGINE_MAX_POWER_AT_RPM_ELECTRIC_ENGINE_VALUE = 'ENGINE_MAX_POWER_AT_RPM_ELECTRIC_ENGINE_VALUE',
    ENGINE_MAX_POWER_AT_RPM_ELECTRIC_ENGINE_RANGE = 'ENGINE_MAX_POWER_AT_RPM_ELECTRIC_ENGINE_RANGE',
    ENGINE_MAX_RPM = 'ENGINE_MAX_RPM',
    ENGINE_ENGINE_LAYOUT = 'ENGINE_ENGINE_LAYOUT',
    ENGINE_ENGINE_DESIGN = 'ENGINE_ENGINE_DESIGN',
    ENGINE_TRANSMISSION = 'ENGINE_TRANSMISSION',
    ENGINE_FUEL_TYPE = 'ENGINE_FUEL_TYPE',
    ENGINE_ENGINE_BORE = 'ENGINE_ENGINE_BORE',
    ENGINE_ENGINE_STROKE = 'ENGINE_ENGINE_STROKE',
    ENGINE_MAX_POWER_PER_LITER_COMBUSTION_ENGINE_KILOWATTS_PER_LITER = 'ENGINE_MAX_POWER_PER_LITER_COMBUSTION_ENGINE_KILOWATTS_PER_LITER',
    ENGINE_MAX_POWER_PER_LITER_COMBUSTION_ENGINE_HORSEPOWER_PER_LITER = 'ENGINE_MAX_POWER_PER_LITER_COMBUSTION_ENGINE_HORSEPOWER_PER_LITER',
    PERFORMANCE_TOP_SPEED = 'PERFORMANCE_TOP_SPEED',
    PERFORMANCE_ELECTRICAL_TOP_SPEED = 'PERFORMANCE_ELECTRICAL_TOP_SPEED',
    PERFORMANCE_ACCELERATION_FROM_0_TO_100_KILOMETERS_PER_HOUR = 'PERFORMANCE_ACCELERATION_FROM_0_TO_100_KILOMETERS_PER_HOUR',
    PERFORMANCE_ACCELERATION_FROM_0_TO_100_KILOMETERS_PER_HOUR_LAUNCH_CONTROL = 'PERFORMANCE_ACCELERATION_FROM_0_TO_100_KILOMETERS_PER_HOUR_LAUNCH_CONTROL',
    PERFORMANCE_ACCELERATION_FROM_0_TO_100_KILOMETERS_PER_HOUR_SPORT_CHRONO = 'PERFORMANCE_ACCELERATION_FROM_0_TO_100_KILOMETERS_PER_HOUR_SPORT_CHRONO',
    PERFORMANCE_ACCELERATION_FROM_0_TO_160_KILOMETERS_PER_HOUR = 'PERFORMANCE_ACCELERATION_FROM_0_TO_160_KILOMETERS_PER_HOUR',
    PERFORMANCE_ACCELERATION_FROM_0_TO_160_KILOMETERS_PER_HOUR_LAUNCH_CONTROL = 'PERFORMANCE_ACCELERATION_FROM_0_TO_160_KILOMETERS_PER_HOUR_LAUNCH_CONTROL',
    PERFORMANCE_ACCELERATION_FROM_0_TO_160_KILOMETERS_PER_HOUR_SPORT_CHRONO = 'PERFORMANCE_ACCELERATION_FROM_0_TO_160_KILOMETERS_PER_HOUR_SPORT_CHRONO',
    PERFORMANCE_ACCELERATION_FROM_0_TO_200_KILOMETERS_PER_HOUR = 'PERFORMANCE_ACCELERATION_FROM_0_TO_200_KILOMETERS_PER_HOUR',
    PERFORMANCE_ACCELERATION_FROM_0_TO_200_KILOMETERS_PER_HOUR_LAUNCH_CONTROL = 'PERFORMANCE_ACCELERATION_FROM_0_TO_200_KILOMETERS_PER_HOUR_LAUNCH_CONTROL',
    PERFORMANCE_ACCELERATION_FROM_0_TO_200_KILOMETERS_PER_HOUR_SPORT_CHRONO = 'PERFORMANCE_ACCELERATION_FROM_0_TO_200_KILOMETERS_PER_HOUR_SPORT_CHRONO',
    PERFORMANCE_ACCELERATION_FROM_0_TO_300_KILOMETERS_PER_HOUR = 'PERFORMANCE_ACCELERATION_FROM_0_TO_300_KILOMETERS_PER_HOUR',
    PERFORMANCE_ACCELERATION_FROM_80_TO_120_KILOMETERS_PER_HOUR_IN_GEAR = 'PERFORMANCE_ACCELERATION_FROM_80_TO_120_KILOMETERS_PER_HOUR_IN_GEAR',
    PERFORMANCE_ACCELERATION_FROM_80_TO_120_KILOMETERS_PER_HOUR_IN_GEAR_SPORT_CHRONO = 'PERFORMANCE_ACCELERATION_FROM_80_TO_120_KILOMETERS_PER_HOUR_IN_GEAR_SPORT_CHRONO',
    PERFORMANCE_ACCELERATION_FROM_80_TO_120_KILOMETERS_PER_HOUR_ELASTICITY_5TH_GEAR = 'PERFORMANCE_ACCELERATION_FROM_80_TO_120_KILOMETERS_PER_HOUR_ELASTICITY_5TH_GEAR',
    PERFORMANCE_ACCELERATION_FROM_0_TO_60_MILES_PER_HOUR = 'PERFORMANCE_ACCELERATION_FROM_0_TO_60_MILES_PER_HOUR',
    PERFORMANCE_ACCELERATION_FROM_0_TO_60_MILES_PER_HOUR_LAUNCH_CONTROL = 'PERFORMANCE_ACCELERATION_FROM_0_TO_60_MILES_PER_HOUR_LAUNCH_CONTROL',
    PERFORMANCE_ACCELERATION_FROM_0_TO_60_MILES_PER_HOUR_SPORT_CHRONO = 'PERFORMANCE_ACCELERATION_FROM_0_TO_60_MILES_PER_HOUR_SPORT_CHRONO',
    PERFORMANCE_ACCELERATION_QUARTER_MILE = 'PERFORMANCE_ACCELERATION_QUARTER_MILE',
    PERFORMANCE_ACCELERATION_QUARTER_MILE_SPORT_CHRONO = 'PERFORMANCE_ACCELERATION_QUARTER_MILE_SPORT_CHRONO',
    BODY_UNLADEN_WEIGHT_DIN = 'BODY_UNLADEN_WEIGHT_DIN',
    BODY_UNLADEN_WEIGHT_EC = 'BODY_UNLADEN_WEIGHT_EC',
    BODY_UNLADEN_WEIGHT_INDIVIDUAL_VEHICLE = 'BODY_UNLADEN_WEIGHT_INDIVIDUAL_VEHICLE',
    BODY_LOAD_MAXIMUM_LOAD = 'BODY_LOAD_MAXIMUM_LOAD',
    BODY_LOAD_MAXIMUM_ROOF_LOAD = 'BODY_LOAD_MAXIMUM_ROOF_LOAD',
    BODY_PERMISSIBLE_GROSS_WEIGHT = 'BODY_PERMISSIBLE_GROSS_WEIGHT',
    BODY_LENGTH = 'BODY_LENGTH',
    BODY_WIDTH_WITH_MIRRORS = 'BODY_WIDTH_WITH_MIRRORS',
    BODY_WIDTH_WITHOUT_MIRRORS = 'BODY_WIDTH_WITHOUT_MIRRORS',
    BODY_HEIGHT = 'BODY_HEIGHT',
    BODY_WHEEL_BASE = 'BODY_WHEEL_BASE',
    BODY_TRACK_FRONT = 'BODY_TRACK_FRONT',
    BODY_TRACK_REAR = 'BODY_TRACK_REAR',
    BODY_TURNING_RADIUS = 'BODY_TURNING_RADIUS',
    BODY_FUEL_TANK_CAPACITY = 'BODY_FUEL_TANK_CAPACITY',
    BODY_LUGGAGE_VOLUME = 'BODY_LUGGAGE_VOLUME',
    BODY_LUGGAGE_VOLUME_SEATS_FOLDED = 'BODY_LUGGAGE_VOLUME_SEATS_FOLDED',
    BODY_LUGGAGE_FRONT_VOLUME = 'BODY_LUGGAGE_FRONT_VOLUME',
    BODY_LUGGAGE_REAR_VOLUME = 'BODY_LUGGAGE_REAR_VOLUME',
    BODY_LUGGAGE_MAX_VOLUME_REAR = 'BODY_LUGGAGE_MAX_VOLUME_REAR',
    BODY_DRAG_COEFFICIENT = 'BODY_DRAG_COEFFICIENT',
    BODY_TRAILER_COUPLING_MAX_TOWING_LOAD_BRAKED = 'BODY_TRAILER_COUPLING_MAX_TOWING_LOAD_BRAKED',
    BODY_TRAILER_COUPLING_MAX_TOWING_LOAD_UNBRAKED = 'BODY_TRAILER_COUPLING_MAX_TOWING_LOAD_UNBRAKED',
    BODY_TRAILER_COUPLING_MAX_VERTICAL_LOAD = 'BODY_TRAILER_COUPLING_MAX_VERTICAL_LOAD',
    BODY_MAX_WADING_DEPTH_STEEL_SUSPENSION = 'BODY_MAX_WADING_DEPTH_STEEL_SUSPENSION',
    BODY_MAX_WADING_DEPTH_AIR_SUSPENSION_HIGH_LEVEL = 'BODY_MAX_WADING_DEPTH_AIR_SUSPENSION_HIGH_LEVEL',
    BODY_APPROACH_DEPARTURE_ANGLE_STEEL_SUSPENSION_FRONT = 'BODY_APPROACH_DEPARTURE_ANGLE_STEEL_SUSPENSION_FRONT',
    BODY_APPROACH_DEPARTURE_ANGLE_STEEL_SUSPENSION_REAR = 'BODY_APPROACH_DEPARTURE_ANGLE_STEEL_SUSPENSION_REAR',
    BODY_APPROACH_DEPARTURE_ANGLE_AIR_SUSPENSION_HIGH_LEVEL_FRONT = 'BODY_APPROACH_DEPARTURE_ANGLE_AIR_SUSPENSION_HIGH_LEVEL_FRONT',
    BODY_APPROACH_DEPARTURE_ANGLE_AIR_SUSPENSION_HIGH_LEVEL_REAR = 'BODY_APPROACH_DEPARTURE_ANGLE_AIR_SUSPENSION_HIGH_LEVEL_REAR',
    BODY_RAMP_BREAK_OVER_ANGLE_STEEL_SUSPENSION = 'BODY_RAMP_BREAK_OVER_ANGLE_STEEL_SUSPENSION',
    BODY_RAMP_BREAK_OVER_ANGLE_AIR_SUSPENSION_HIGH_LEVEL = 'BODY_RAMP_BREAK_OVER_ANGLE_AIR_SUSPENSION_HIGH_LEVEL',
    BODY_MAX_GROUND_CLEARANCE_STEEL_SUSPENSION = 'BODY_MAX_GROUND_CLEARANCE_STEEL_SUSPENSION',
    BODY_MAX_GROUND_CLEARANCE_AIR_SUSPENSION_NORMAL_LEVEL = 'BODY_MAX_GROUND_CLEARANCE_AIR_SUSPENSION_NORMAL_LEVEL',
    BODY_MAX_GROUND_CLEARANCE_AIR_SUSPENSION_HIGH_LEVEL = 'BODY_MAX_GROUND_CLEARANCE_AIR_SUSPENSION_HIGH_LEVEL',
    CONSUMPTION_EMISSION_GENERAL_FUEL_CONSUMPTION_COMBINED = 'CONSUMPTION_EMISSION_GENERAL_FUEL_CONSUMPTION_COMBINED',
    CONSUMPTION_EMISSION_GENERAL_FUEL_CONSUMPTION_URBAN = 'CONSUMPTION_EMISSION_GENERAL_FUEL_CONSUMPTION_URBAN',
    CONSUMPTION_EMISSION_GENERAL_FUEL_CONSUMPTION_EXTRA_URBAN = 'CONSUMPTION_EMISSION_GENERAL_FUEL_CONSUMPTION_EXTRA_URBAN',
    CONSUMPTION_EMISSION_GENERAL_FUEL_CONSUMPTION_US_SPECIFICS_COMBINED_MPGE = 'CONSUMPTION_EMISSION_GENERAL_FUEL_CONSUMPTION_US_SPECIFICS_COMBINED_MPGE',
    CONSUMPTION_EMISSION_GENERAL_ELECTRICITY_CONSUMPTION_COMBINED = 'CONSUMPTION_EMISSION_GENERAL_ELECTRICITY_CONSUMPTION_COMBINED',
    CONSUMPTION_EMISSION_GENERAL_CO2_EMISSIONS = 'CONSUMPTION_EMISSION_GENERAL_CO2_EMISSIONS',
    CONSUMPTION_EMISSION_GENERAL_CH_SPECIFICS_FUEL_EQUIVALENT = 'CONSUMPTION_EMISSION_GENERAL_CH_SPECIFICS_FUEL_EQUIVALENT',
    CONSUMPTION_EMISSION_GENERAL_CH_SPECIFICS_CO2_EMISSIONS_FUEL_AND_ELECTRIC = 'CONSUMPTION_EMISSION_GENERAL_CH_SPECIFICS_CO2_EMISSIONS_FUEL_AND_ELECTRIC',
    CHARGING_BATTERY_CAPACITY_GROSS = 'CHARGING_BATTERY_CAPACITY_GROSS',
    CHARGING_BATTERY_CAPACITY_NET = 'CHARGING_BATTERY_CAPACITY_NET',
    CHARGING_CHARGING_TIME_AC_9POINT6_KW_TO_100_PERCENT = 'CHARGING_CHARGING_TIME_AC_9POINT6_KW_TO_100_PERCENT',
    CHARGING_CHARGING_TIME_AC_11_KW_TO_100_PERCENT = 'CHARGING_CHARGING_TIME_AC_11_KW_TO_100_PERCENT',
    CHARGING_CHARGING_TIME_DC_50_KW_UP_TO_100_KM = 'CHARGING_CHARGING_TIME_DC_50_KW_UP_TO_100_KM',
    CHARGING_CHARGING_TIME_DC_270_KW_UP_TO_100_KM = 'CHARGING_CHARGING_TIME_DC_270_KW_UP_TO_100_KM',
    CHARGING_CHARGING_TIME_DC_50_KW_5_TO_80_PERCENT = 'CHARGING_CHARGING_TIME_DC_50_KW_5_TO_80_PERCENT',
    CHARGING_CHARGING_TIME_DC_270_KW_5_TO_80_PERCENT = 'CHARGING_CHARGING_TIME_DC_270_KW_5_TO_80_PERCENT',
    CHARGING_CHARGING_TIME_DC_120_KW_10_TO_80_PERCENT = 'CHARGING_CHARGING_TIME_DC_120_KW_10_TO_80_PERCENT',
    CHARGING_CHARGING_TIME_DC_150_KW_10_TO_80_PERCENT = 'CHARGING_CHARGING_TIME_DC_150_KW_10_TO_80_PERCENT',
    CHARGING_CHARGING_TIME_DC_MAX_KW_10_TO_80_PERCENT = 'CHARGING_CHARGING_TIME_DC_MAX_KW_10_TO_80_PERCENT',
    CHARGING_CHARGING_TIME_DC_400_A_10_TO_80_PERCENT = 'CHARGING_CHARGING_TIME_DC_400_A_10_TO_80_PERCENT',
    CHARGING_MAX_CHARGING_POWER_DC = 'CHARGING_MAX_CHARGING_POWER_DC',
    RANGE_WLTP_LONG_DISTANCE = 'RANGE_WLTP_LONG_DISTANCE',
    RANGE_WLTP_COMBINED_CONFIG_SPECIFIC = 'RANGE_WLTP_COMBINED_CONFIG_SPECIFIC',
    RANGE_WLTP_CITY_CONFIG_SPECIFIC = 'RANGE_WLTP_CITY_CONFIG_SPECIFIC',
    RANGE_WLTP_ELECTRICAL_CONFIG_SPECIFIC = 'RANGE_WLTP_ELECTRICAL_CONFIG_SPECIFIC',
    RANGE_WLTP_ELECTRICAL_CITY_CONFIG_SPECIFIC = 'RANGE_WLTP_ELECTRICAL_CITY_CONFIG_SPECIFIC',
    RANGE_EPA = 'RANGE_EPA',
    RANGE_ECE_ELECTRICALCOMBINED = 'RANGE_ECE_ELECTRICALCOMBINED'
}

export function TechnicalDataAttributeFromJSON(json: any): TechnicalDataAttribute {
    return TechnicalDataAttributeFromJSONTyped(json, false);
}

export function TechnicalDataAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataAttribute {
    return json as TechnicalDataAttribute;
}

export function TechnicalDataAttributeToJSON(value?: TechnicalDataAttribute | null): any {
    return value as any;
}

