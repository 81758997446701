import { SelectableButton } from "../buttons/selectablebutton/SelectableButton"
import { SpacedItems } from "../layouts/spaceditems/SpacedItems"
import { spacing } from "@porsche-design-system/utilities"
import { FC } from "react"

export interface TabBarProps {
    tabLabels: string[]
    hoveredTab?: number
    setHoveredTab: (hoveredTab?: number) => void
    selectedTab: number
    setSelectedTab: (selectedTab: number) => void
}

export const TabBar: FC<TabBarProps> = (props) => (
    <SpacedItems direction={"row"} itemSpacing={spacing[32]}>
        {props.tabLabels.map((tabLabel, index) => (
            <SelectableButton
                key={tabLabel}
                label={tabLabel}
                height={"2rem"}
                selectionLineHeight={"3px"}
                isHovered={props.hoveredTab === index}
                isSelected={props.selectedTab === index}
                onClick={() => props.setSelectedTab(index)}
                onMouseEnterText={() => props.setHoveredTab(index)}
                onMouseLeaveText={() => props.hoveredTab === index && props.setHoveredTab(undefined)}
            />
        ))}
    </SpacedItems>
)
