/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExteriorColorCustomizationAttributesDTOValue,
    ExteriorColorCustomizationAttributesDTOValueFromJSON,
    ExteriorColorCustomizationAttributesDTOValueFromJSONTyped,
    ExteriorColorCustomizationAttributesDTOValueToJSON,
    MultiSelectDTOString,
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValue,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValueFromJSON,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExteriorColorCustomizationAttributesDTO
 */
export interface ExteriorColorCustomizationAttributesDTO {
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorCustomizationAttributesDTO
     */
    optionCode: string;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof ExteriorColorCustomizationAttributesDTO
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {boolean}
     * @memberof ExteriorColorCustomizationAttributesDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {ExteriorColorCustomizationAttributesDTOValue}
     * @memberof ExteriorColorCustomizationAttributesDTO
     */
    defaultValue: ExteriorColorCustomizationAttributesDTOValue;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValue>}
     * @memberof ExteriorColorCustomizationAttributesDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValue>;
}

export function ExteriorColorCustomizationAttributesDTOFromJSON(json: any): ExteriorColorCustomizationAttributesDTO {
    return ExteriorColorCustomizationAttributesDTOFromJSONTyped(json, false);
}

export function ExteriorColorCustomizationAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorCustomizationAttributesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': ExteriorColorCustomizationAttributesDTOValueFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValueFromJSON)),
    };
}

export function ExteriorColorCustomizationAttributesDTOToJSON(value?: ExteriorColorCustomizationAttributesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerations': MultiSelectDTOStringToJSON(value.modelGenerations),
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': ExteriorColorCustomizationAttributesDTOValueToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValueToJSON)),
    };
}


