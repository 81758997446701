/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExteriorColorCustomizationAttributesDTOValue,
    ExteriorColorCustomizationAttributesDTOValueFromJSON,
    ExteriorColorCustomizationAttributesDTOValueFromJSONTyped,
    ExteriorColorCustomizationAttributesDTOValueToJSON,
    IntRange,
    IntRangeFromJSON,
    IntRangeFromJSONTyped,
    IntRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValue
 */
export interface OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValue
     */
    key: string;
    /**
     * 
     * @type {IntRange}
     * @memberof OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValue
     */
    modelYearRange: IntRange;
    /**
     * 
     * @type {ExteriorColorCustomizationAttributesDTOValue}
     * @memberof OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValue
     */
    value: ExteriorColorCustomizationAttributesDTOValue;
}

export function OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValueFromJSON(json: any): OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValue {
    return OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': IntRangeFromJSON(json['modelYearRange']),
        'value': ExteriorColorCustomizationAttributesDTOValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValueToJSON(value?: OptionCustomizationConditionalValueDTOExteriorColorCustomizationAttributesDTOValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'modelYearRange': IntRangeToJSON(value.modelYearRange),
        'value': ExteriorColorCustomizationAttributesDTOValueToJSON(value.value),
    };
}


