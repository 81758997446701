import { propertiesOf } from "../../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../../components/formelements/EditFormElementWrapper"
import { getNumberFormElement } from "../../../../../../components/formelements/numberformelement/NumberFormElement"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { ExteriorColorCreateRequestDTO } from "../../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { useOrderTypeOptionsContext } from "../../../OrderTypeOptionsContext"
import { FC } from "react"

export const OrderTypeExteriorColorCreateOptionCard: FC<ProductCreateContentProps<ExteriorColorCreateRequestDTO, unknown>> = ({ formDisplayData }) => {
    const properties = propertiesOf<ExteriorColorCreateRequestDTO>()
    const { orderTypeKey, orderTypeName } = useOrderTypeOptionsContext()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Order Type"}
                    formDisplayData={formDisplayData}
                    field={properties.orderTypeKey}
                    FormElement={getSelectTextFormElement<unknown, ExteriorColorCreateRequestDTO>()}
                    getEditProps={() => ({
                        options: [{ value: orderTypeKey, label: orderTypeName }],
                        value: orderTypeKey,
                        readOnly: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={properties.optionCode}
                    FormElement={getTextFormElement<unknown, ExteriorColorCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: "",
                    })}
                />
                <EditFormElementWrapper
                    label={"Sort Index"}
                    formDisplayData={formDisplayData}
                    field={properties.sortIndex}
                    FormElement={getNumberFormElement<unknown, ExteriorColorCreateRequestDTO>()}
                    getEditProps={() => ({
                        step: 1,
                        min: 0,
                        value: undefined,
                        optional: true,
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    optional: true,
                    value: { de: "", en: "" },
                })}
            />
        </SpacedItems>
    )
}
