/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InteriorCustomizationAttributesUpdateDTOValueColor
 */
export interface InteriorCustomizationAttributesUpdateDTOValueColor {
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesUpdateDTOValueColor
     */
    seatColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesUpdateDTOValueColor
     */
    seatStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesUpdateDTOValueColor
     */
    cockpitColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesUpdateDTOValueColor
     */
    cockpitStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesUpdateDTOValueColor
     */
    tagKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InteriorCustomizationAttributesUpdateDTOValueColor
     */
    isCustomColor: boolean;
}

export function InteriorCustomizationAttributesUpdateDTOValueColorFromJSON(json: any): InteriorCustomizationAttributesUpdateDTOValueColor {
    return InteriorCustomizationAttributesUpdateDTOValueColorFromJSONTyped(json, false);
}

export function InteriorCustomizationAttributesUpdateDTOValueColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationAttributesUpdateDTOValueColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seatColorHexCode': json['seatColorHexCode'],
        'seatStitchingColorHexCode': json['seatStitchingColorHexCode'],
        'cockpitColorHexCode': json['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': json['cockpitStitchingColorHexCode'],
        'tagKey': !exists(json, 'tagKey') ? undefined : json['tagKey'],
        'isCustomColor': json['isCustomColor'],
    };
}

export function InteriorCustomizationAttributesUpdateDTOValueColorToJSON(value?: InteriorCustomizationAttributesUpdateDTOValueColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seatColorHexCode': value.seatColorHexCode,
        'seatStitchingColorHexCode': value.seatStitchingColorHexCode,
        'cockpitColorHexCode': value.cockpitColorHexCode,
        'cockpitStitchingColorHexCode': value.cockpitStitchingColorHexCode,
        'tagKey': value.tagKey,
        'isCustomColor': value.isCustomColor,
    };
}


