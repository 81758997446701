/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    OptionalSelectDTOExteriorColorType,
    OptionalSelectDTOExteriorColorTypeFromJSON,
    OptionalSelectDTOExteriorColorTypeFromJSONTyped,
    OptionalSelectDTOExteriorColorTypeToJSON,
    OptionalSelectDTOString,
    OptionalSelectDTOStringFromJSON,
    OptionalSelectDTOStringFromJSONTyped,
    OptionalSelectDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExteriorColorCustomizationAttributesDTOValue
 */
export interface ExteriorColorCustomizationAttributesDTOValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof ExteriorColorCustomizationAttributesDTOValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof ExteriorColorCustomizationAttributesDTOValue
     */
    hexCode: string;
    /**
     * 
     * @type {OptionalSelectDTOString}
     * @memberof ExteriorColorCustomizationAttributesDTOValue
     */
    tag: OptionalSelectDTOString;
    /**
     * 
     * @type {boolean}
     * @memberof ExteriorColorCustomizationAttributesDTOValue
     */
    isCustomColor: boolean;
    /**
     * 
     * @type {OptionalSelectDTOExteriorColorType}
     * @memberof ExteriorColorCustomizationAttributesDTOValue
     */
    exteriorColorType: OptionalSelectDTOExteriorColorType;
}

export function ExteriorColorCustomizationAttributesDTOValueFromJSON(json: any): ExteriorColorCustomizationAttributesDTOValue {
    return ExteriorColorCustomizationAttributesDTOValueFromJSONTyped(json, false);
}

export function ExteriorColorCustomizationAttributesDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorCustomizationAttributesDTOValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'hexCode': json['hexCode'],
        'tag': OptionalSelectDTOStringFromJSON(json['tag']),
        'isCustomColor': json['isCustomColor'],
        'exteriorColorType': OptionalSelectDTOExteriorColorTypeFromJSON(json['exteriorColorType']),
    };
}

export function ExteriorColorCustomizationAttributesDTOValueToJSON(value?: ExteriorColorCustomizationAttributesDTOValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'hexCode': value.hexCode,
        'tag': OptionalSelectDTOStringToJSON(value.tag),
        'isCustomColor': value.isCustomColor,
        'exteriorColorType': OptionalSelectDTOExteriorColorTypeToJSON(value.exteriorColorType),
    };
}


