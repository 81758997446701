/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    InteriorOptionDTOBaseLocalization,
    InteriorOptionDTOBaseLocalizationFromJSON,
    InteriorOptionDTOBaseLocalizationFromJSONTyped,
    InteriorOptionDTOBaseLocalizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorOptionDTO
 */
export interface InteriorOptionDTO {
    /**
     * 
     * @type {string}
     * @memberof InteriorOptionDTO
     */
    optionCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorOptionDTO
     */
    optionType?: string;
    /**
     * 
     * @type {number}
     * @memberof InteriorOptionDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {InteriorOptionDTOBaseLocalization}
     * @memberof InteriorOptionDTO
     */
    name: InteriorOptionDTOBaseLocalization;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof InteriorOptionDTO
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof InteriorOptionDTO
     */
    imageKeys: Array<string>;
}

export function InteriorOptionDTOFromJSON(json: any): InteriorOptionDTO {
    return InteriorOptionDTOFromJSONTyped(json, false);
}

export function InteriorOptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorOptionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'optionType': !exists(json, 'optionType') ? undefined : json['optionType'],
        'sortIndex': json['sortIndex'],
        'name': InteriorOptionDTOBaseLocalizationFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'imageKeys': json['imageKeys'],
    };
}

export function InteriorOptionDTOToJSON(value?: InteriorOptionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'optionType': value.optionType,
        'sortIndex': value.sortIndex,
        'name': InteriorOptionDTOBaseLocalizationToJSON(value.name),
        'description': BaseNameDTOToJSON(value.description),
        'imageKeys': value.imageKeys,
    };
}


