import { getCurrentEnvironment } from "../../common/environment"
import { UserAuthority } from "../../generated/pdsapi"
import { PdsHeaderNavigationItem } from "./PdsHeader"

export const pdsHeaderConfig: PdsHeaderNavigationItem[] = [
    {
        label: "Models",
        to: "/models",
        items: [
            {
                label: "Model Series",
                to: "/model-series",
            },
            {
                label: "Model Generations",
                to: "/model-generations",
            },
            {
                label: "Derivative Categories",
                to: "/derivative-categories",
            },
            {
                label: "Derivatives",
                to: "/derivatives",
            },
            {
                label: "Order Types",
                to: "/order-types",
            },
        ],
        requiredUserAuthority: UserAuthority.VIEWER,
    },
    {
        label: "Equipment",
        to: "/equipment",
        items: [
            {
                label: "Equipment Catalogue",
                to: "/catalogue",
            },
            {
                label: "Equipment Customizations",
                to: "/customizations",
            },
            {
                label: "Equipment Tags",
                to: "/tags",
            },
        ],
        requiredUserAuthority: UserAuthority.VIEWER,
    },
    {
        label: "Exterior Colors",
        to: "/exterior-colors",
        items: [
            {
                label: "Exterior Colors Catalogue",
                to: "/catalogue",
            },
            {
                label: "Exterior Color Customizations",
                to: "/customizations",
            },
            {
                label: "Exterior Color Tags",
                to: "/tags",
            },
            {
                label: "Custom Exterior Colors",
                to: "/custom-exterior-colors",
            },
        ],
        requiredUserAuthority: UserAuthority.VIEWER,
    },
    {
        label: "Interiors",
        to: "/interiors",
        items: [
            {
                label: "Interiors Catalogue",
                to: "/catalogue",
            },
            {
                label: "Interior Customizations",
                to: "/customizations",
            },
            {
                label: "Interior Color Tags",
                to: "/color-tags",
            },
            {
                label: "Custom Interior Colors",
                to: "/custom-interior-colors",
            },
        ],
        requiredUserAuthority: UserAuthority.VIEWER,
    },
    {
        label: "Roof Colors",
        to: "/roof-colors",
        items: [
            {
                label: "Roof Colors Catalogue",
                to: "/catalogue",
            },
            {
                label: "Roof Color Customizations",
                to: "/customizations",
            },
            {
                label: "Roof Color Tags",
                to: "/tags",
            },
        ],
        requiredUserAuthority: UserAuthority.VIEWER,
    },
    {
        label: "Other",
        to: "/other",
        items: [
            {
                label: "Technical Data",
                to: "/technical-data",
            },
        ],
        requiredUserAuthority: UserAuthority.VIEWER,
    },
    {
        label: "Administration",
        to: "/admin",
        items: [
            {
                label: "Swagger-UI",
                customRef: `${getCurrentEnvironment().backendUrl}/swagger-ui.html`,
            },
            {
                label: "GraphQL (Altair)",
                customRef: `${getCurrentEnvironment().backendUrl}/altair`,
            },
        ],
        requiredUserAuthority: UserAuthority.ADMINISTRATOR,
    },
]
