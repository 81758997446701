import { BootInfo } from "../pdsapi"
import { PdsBootContext, PdsBootContextProps } from "./PdsBootContext"
import { getLanguageGroups } from "./getLanguageGroups"
import { FC, ReactNode, useEffect, useState } from "react"
import { useIntl } from "react-intl"

export interface PdsBootContextProviderProps {
    children: ReactNode
    getBootInfo: () => Promise<BootInfo>
}

export const PdsBootContextProvider: FC<PdsBootContextProviderProps> = ({ children, getBootInfo }) => {
    const intl = useIntl()

    const [pdsBootContext, setPdsBootContext] = useState<PdsBootContextProps>({
        hasAuthority: () => false,
        supportedLanguageGroups: [],
        supportedMarketplaces: [],
    })

    useEffect(() => {
        ;(async () => {
            const bootInfo = await getBootInfo()
            setPdsBootContext({
                hasAuthority: (userAuthority) => bootInfo.authorities.includes(userAuthority),
                supportedMarketplaces: bootInfo.supportedMarketplaces.sort((a, b) => a.label.localeCompare(b.label)),
                supportedLanguageGroups: getLanguageGroups(bootInfo.supportedLanguageTags, intl),
            })
        })()
    }, [getBootInfo, intl])

    return <PdsBootContext.Provider value={pdsBootContext}>{children}</PdsBootContext.Provider>
}
