import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { ModelGenerationItemDTO } from "../../../../../generated/pdsapi"
import { ModelGenerationSortField, ModelGenerationSortFieldEnum } from "../../../../../pdsapi"
import { activeColumn } from "../../../../../viewtemplates/list/columns/activeColumn"
import { PIcon } from "@porsche-design-system/components-react"

type ModelGenerationColumnBuilder = ColumnBuilder<ModelGenerationItemDTO, ModelGenerationSortFieldEnum>

export const ModelGenerationListColumnsConfig: TableColumnsConfig<ModelGenerationItemDTO, ModelGenerationSortFieldEnum> = ({ sorting, setSorting }) => {
    const sortIndexColumn: ModelGenerationColumnBuilder = {
        width: 40,
        headerCellProps: {
            content: "#",
            sorting: {
                field: ModelGenerationSortField.SORT_INDEX,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (modelGeneration) => modelGeneration.sortIndex.toString(),
    }
    const nameColumn: ModelGenerationColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (modelGeneration) => modelGeneration.name,
    }
    const modelSeriesColumn: ModelGenerationColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Model Series",
        },
        buildCellContent: (modelGeneration) => modelGeneration.modelSeries,
    }
    const faceliftOfColumn: ModelGenerationColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Facelift of",
        },
        buildCellContent: (modelGeneration) => modelGeneration.faceliftOf ?? "-",
    }
    const classicColumn: ModelGenerationColumnBuilder = {
        width: 50,
        headerCellProps: {
            content: "Classic",
        },
        buildCellContent: (modelGeneration) => (modelGeneration.classic ? <PIcon name={"check"} aria-label={"classic icon"} /> : "-"),
    }

    return [sortIndexColumn, nameColumn, modelSeriesColumn, faceliftOfColumn, activeColumn, classicColumn]
}
