import { TableColumnsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import { ColumnBuilder } from "../../../../../../../components/layouts/table/Table"
import { OptionImage } from "../../../../../../../components/optionimage/OptionImage"
import { getSortIndexColumnBuilder } from "../../../../../../../components/pagedtable/columns/columnBuilder"
import { NamedColorTile } from "../../../../../../../components/tiles/namedcolortile/NamedColorTile"
import { OrderTypeInteriorItemDTO } from "../../../../../../../generated/pdsapi"
import { InteriorSortField, InteriorSortFieldEnum } from "../../../../../../../pdsapi"
import { InteriorColorTile } from "@finder/ui-kit"
import { PText } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"

type InteriorItemCardColumnBuilder = ColumnBuilder<OrderTypeInteriorItemDTO, InteriorSortFieldEnum>

export const OrderTypeInteriorsCardColumnsConfig: TableColumnsConfig<OrderTypeInteriorItemDTO, InteriorSortFieldEnum> = ({ sorting, setSorting }) => {
    const previewColumn: InteriorItemCardColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (interior) => <OptionImage imageKey={interior.imageKey} sizes={"100px"} />,
    }

    const sortIndexColumn: InteriorItemCardColumnBuilder = getSortIndexColumnBuilder((interior) => interior.sortIndex, {
        field: InteriorSortField.SORT_INDEX,
        value: sorting,
        setValue: setSorting,
    })

    const optionCodeColumn: InteriorItemCardColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (interior) => interior.optionCode,
    }

    const nameColumn: InteriorItemCardColumnBuilder = {
        grow: 6,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (interior) => interior.name ?? "-",
    }

    const interiorMaterialColumn: InteriorItemCardColumnBuilder = {
        grow: 4,
        headerCellProps: {
            content: "Material",
        },
        buildCellContent: (interior) => interior.materialName ?? "-",
    }

    const colorColumn: InteriorItemCardColumnBuilder = {
        grow: 4,
        headerCellProps: {
            content: "Color",
        },
        buildCellContent: (interior) => {
            const color = interior.color

            return color ? (
                <SpacedItems direction={"row"} itemSpacing={spacing[8]}>
                    <InteriorColorTile
                        seatColor={color.seatColorHexCode}
                        seatStitchingColor={color.seatStitchingColorHexCode}
                        cockpitColor={color.cockpitColorHexCode}
                        cockpitStitchingColor={color.cockpitStitchingColorHexCode}
                    />
                    <PText>{color.label}</PText>
                </SpacedItems>
            ) : (
                "-"
            )
        },
    }

    const tagColumn: InteriorItemCardColumnBuilder = {
        grow: 4,
        headerCellProps: {
            content: "Color Tag",
        },
        buildCellContent: (interior) => {
            const interiorColorTag = interior.colorTag

            return interiorColorTag ? <NamedColorTile label={interiorColorTag.label} hexCode={interiorColorTag.hexCode} /> : "-"
        },
    }

    return [previewColumn, sortIndexColumn, optionCodeColumn, nameColumn, interiorMaterialColumn, colorColumn, tagColumn]
}
