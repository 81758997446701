/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FuelType {
    DIESEL = 'DIESEL',
    REGULAR_PETROL = 'REGULAR_PETROL',
    PREMIUM_PETROL = 'PREMIUM_PETROL'
}

export function FuelTypeFromJSON(json: any): FuelType {
    return FuelTypeFromJSONTyped(json, false);
}

export function FuelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelType {
    return json as FuelType;
}

export function FuelTypeToJSON(value?: FuelType | null): any {
    return value as any;
}

