/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DerivativeCategoryItemDTO
 */
export interface DerivativeCategoryItemDTO {
    /**
     * 
     * @type {string}
     * @memberof DerivativeCategoryItemDTO
     */
    key: string;
    /**
     * 
     * @type {number}
     * @memberof DerivativeCategoryItemDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof DerivativeCategoryItemDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DerivativeCategoryItemDTO
     */
    modelSeries: string;
    /**
     * 
     * @type {boolean}
     * @memberof DerivativeCategoryItemDTO
     */
    active: boolean;
}

export function DerivativeCategoryItemDTOFromJSON(json: any): DerivativeCategoryItemDTO {
    return DerivativeCategoryItemDTOFromJSONTyped(json, false);
}

export function DerivativeCategoryItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeCategoryItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'sortIndex': json['sortIndex'],
        'name': json['name'],
        'modelSeries': json['modelSeries'],
        'active': json['active'],
    };
}

export function DerivativeCategoryItemDTOToJSON(value?: DerivativeCategoryItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'sortIndex': value.sortIndex,
        'name': value.name,
        'modelSeries': value.modelSeries,
        'active': value.active,
    };
}


