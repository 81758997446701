import { Bool3Selector } from "../../../../components/selectors/bool3selector/Bool3Selector"
import { FC } from "react"

export interface ActiveFilterProps {
    active?: boolean
    onChange: (active?: boolean) => void
}

export const ActiveFilter: FC<ActiveFilterProps> = ({ active, onChange }) => (
    <Bool3Selector label={"Active"} value={active} onChange={onChange} trueMessage={"Active Only"} falseMessage={"Inactive Only"} undefinedMessage={"All"} />
)
