/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    InteriorCustomizationAttributesDTOValueColor,
    InteriorCustomizationAttributesDTOValueColorFromJSON,
    InteriorCustomizationAttributesDTOValueColorFromJSONTyped,
    InteriorCustomizationAttributesDTOValueColorToJSON,
    SelectDTOInteriorMaterial,
    SelectDTOInteriorMaterialFromJSON,
    SelectDTOInteriorMaterialFromJSONTyped,
    SelectDTOInteriorMaterialToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorCustomizationAttributesDTOValue
 */
export interface InteriorCustomizationAttributesDTOValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof InteriorCustomizationAttributesDTOValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {InteriorCustomizationAttributesDTOValueColor}
     * @memberof InteriorCustomizationAttributesDTOValue
     */
    color: InteriorCustomizationAttributesDTOValueColor;
    /**
     * 
     * @type {SelectDTOInteriorMaterial}
     * @memberof InteriorCustomizationAttributesDTOValue
     */
    material: SelectDTOInteriorMaterial;
}

export function InteriorCustomizationAttributesDTOValueFromJSON(json: any): InteriorCustomizationAttributesDTOValue {
    return InteriorCustomizationAttributesDTOValueFromJSONTyped(json, false);
}

export function InteriorCustomizationAttributesDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationAttributesDTOValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'color': InteriorCustomizationAttributesDTOValueColorFromJSON(json['color']),
        'material': SelectDTOInteriorMaterialFromJSON(json['material']),
    };
}

export function InteriorCustomizationAttributesDTOValueToJSON(value?: InteriorCustomizationAttributesDTOValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'color': InteriorCustomizationAttributesDTOValueColorToJSON(value.color),
        'material': SelectDTOInteriorMaterialToJSON(value.material),
    };
}


