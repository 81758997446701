import { Property } from "../../../../../common/property"
import { FormDisplayData } from "../../../../../components/formelements/FormDisplayData"
import { FormElementWrapper } from "../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../components/formelements/boolformelement/BoolFormElement"
import { FieldValues } from "react-hook-form"

interface ActiveFormElementProps<DATA, UPDATE extends FieldValues> {
    formDisplayData: FormDisplayData<DATA, UPDATE>
    field: Property<UPDATE, boolean>
    getActive: (data: DATA) => boolean
}

export const ActiveFormElement = <DATA, UPDATE extends FieldValues>({ formDisplayData, getActive, field }: ActiveFormElementProps<DATA, UPDATE>) => (
    <FormElementWrapper
        label={"Active"}
        formDisplayData={formDisplayData}
        field={field}
        FormElement={getBoolFormElement<DATA, UPDATE>()}
        getViewProps={(data) => ({
            value: getActive(data),
            trueMessage: "Active",
            falseMessage: "Inactive",
        })}
        getEditProps={(data) => ({
            value: getActive(data),
            trueMessage: "Active",
            falseMessage: "Inactive",
        })}
    />
)
