/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    GroupedMultiSelectDTOString,
    GroupedMultiSelectDTOStringFromJSON,
    GroupedMultiSelectDTOStringFromJSONTyped,
    GroupedMultiSelectDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface EquipmentCustomizationAttributesDTOValue
 */
export interface EquipmentCustomizationAttributesDTOValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof EquipmentCustomizationAttributesDTOValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof EquipmentCustomizationAttributesDTOValue
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {GroupedMultiSelectDTOString}
     * @memberof EquipmentCustomizationAttributesDTOValue
     */
    equipmentTags: GroupedMultiSelectDTOString;
}

export function EquipmentCustomizationAttributesDTOValueFromJSON(json: any): EquipmentCustomizationAttributesDTOValue {
    return EquipmentCustomizationAttributesDTOValueFromJSONTyped(json, false);
}

export function EquipmentCustomizationAttributesDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentCustomizationAttributesDTOValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'equipmentTags': GroupedMultiSelectDTOStringFromJSON(json['equipmentTags']),
    };
}

export function EquipmentCustomizationAttributesDTOValueToJSON(value?: EquipmentCustomizationAttributesDTOValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'description': BaseNameDTOToJSON(value.description),
        'equipmentTags': GroupedMultiSelectDTOStringToJSON(value.equipmentTags),
    };
}


