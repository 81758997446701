import { propertiesOf } from "../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../components/formelements/EditFormElementWrapper"
import { getSimpleFormWatch } from "../../../../../components/formelements/FormDisplayData"
import { getBoolFormElement } from "../../../../../components/formelements/boolformelement/BoolFormElement"
import { getMultiTextFormElement } from "../../../../../components/formelements/multitextformelement/MultiTextFormElement"
import { getNumberFormElement } from "../../../../../components/formelements/numberformelement/NumberFormElement"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO } from "../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { FC } from "react"

export const OrderTypeCreateAttributesCard: FC<ProductCreateContentProps<OrderTypeCreateRequestDTO, OrderTypeCreateOptionsDTO>> = ({ formDisplayData }) => {
    const properties = propertiesOf<OrderTypeCreateRequestDTO>()

    const watch = getSimpleFormWatch(formDisplayData)
    const options = formDisplayData.data
    const modelGenerationKey = watch("modelGenerationKey")
    const modelSeriesKey = modelGenerationKey ? options?.modelSeriesRelatedModelGenerations.find((mg) => mg.key === modelGenerationKey)?.relatedKey : undefined

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Codes"}
                    formDisplayData={formDisplayData}
                    field={properties.codes}
                    FormElement={getMultiTextFormElement<OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: [],
                    })}
                />
                <EditFormElementWrapper
                    label={"Model Year"}
                    formDisplayData={formDisplayData}
                    field={properties.modelYear}
                    FormElement={getNumberFormElement<OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: 2020,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <EditFormElementWrapper
                    label={"Model Generation"}
                    formDisplayData={formDisplayData}
                    field={properties.modelGenerationKey}
                    FormElement={getSelectTextFormElement<OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: [
                            {
                                value: "",
                                label: "-",
                            },
                            ...createOptions.modelSeriesRelatedModelGenerations.map(toOption),
                        ],
                        value: "",
                    })}
                />
                <EditFormElementWrapper
                    label={"Derivative"}
                    formDisplayData={formDisplayData}
                    field={properties.derivativeKey}
                    FormElement={getSelectTextFormElement<OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: [
                            {
                                value: "",
                                label: "-",
                            },
                            ...(modelSeriesKey
                                ? createOptions.modelSeriesRelatedDerivatives.filter((derivative) => derivative.relatedKey === modelSeriesKey).map(toOption)
                                : []),
                        ],
                        value: "",
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <EditFormElementWrapper
                    label={"Drive Train"}
                    formDisplayData={formDisplayData}
                    field={properties.driveTrain}
                    FormElement={getSelectTextFormElement<OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: [
                            {
                                value: "",
                                label: "-",
                            },
                            ...createOptions.driveTrains.map(toOption),
                        ],
                        value: "",
                    })}
                />
                <EditFormElementWrapper
                    label={"Engine Type"}
                    formDisplayData={formDisplayData}
                    field={properties.engineType}
                    FormElement={getSelectTextFormElement<OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: [
                            {
                                value: "",
                                label: "-",
                            },
                            ...createOptions.engineTypes.map(toOption),
                        ],
                        value: "",
                    })}
                />
                <EditFormElementWrapper
                    label={"Number Of Seats"}
                    formDisplayData={formDisplayData}
                    field={properties.numberOfSeats}
                    FormElement={getSelectTextFormElement<OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: [
                            {
                                value: "",
                                label: "-",
                            },
                            ...createOptions.numberOfSeats.map(toOption),
                        ],
                        value: "",
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.manualName}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                })}
            />

            <RowGroupWrapper label={"Status"}>
                <EditFormElementWrapper
                    label={"Active"}
                    formDisplayData={formDisplayData}
                    field={properties.active}
                    FormElement={getBoolFormElement<OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO>()}
                    getEditProps={() => ({
                        trueMessage: "Active",
                        falseMessage: "Inactive",
                        value: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Special"}
                    formDisplayData={formDisplayData}
                    field={properties.special}
                    FormElement={getBoolFormElement<OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO>()}
                    getEditProps={() => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: false,
                    })}
                />
                <EditFormElementWrapper
                    label={"Limited"}
                    formDisplayData={formDisplayData}
                    field={properties.limited}
                    FormElement={getBoolFormElement<OrderTypeCreateOptionsDTO, OrderTypeCreateRequestDTO>()}
                    getEditProps={() => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: false,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
