/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesUpdateDTOValue,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesUpdateDTOValueFromJSON,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesUpdateDTOValueFromJSONTyped,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesUpdateDTOValueToJSON,
    RoofColorCustomizationAttributesUpdateDTOValue,
    RoofColorCustomizationAttributesUpdateDTOValueFromJSON,
    RoofColorCustomizationAttributesUpdateDTOValueFromJSONTyped,
    RoofColorCustomizationAttributesUpdateDTOValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoofColorCustomizationAttributesUpdateDTO
 */
export interface RoofColorCustomizationAttributesUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof RoofColorCustomizationAttributesUpdateDTO
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoofColorCustomizationAttributesUpdateDTO
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RoofColorCustomizationAttributesUpdateDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {RoofColorCustomizationAttributesUpdateDTOValue}
     * @memberof RoofColorCustomizationAttributesUpdateDTO
     */
    defaultValue: RoofColorCustomizationAttributesUpdateDTOValue;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesUpdateDTOValue>}
     * @memberof RoofColorCustomizationAttributesUpdateDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesUpdateDTOValue>;
}

export function RoofColorCustomizationAttributesUpdateDTOFromJSON(json: any): RoofColorCustomizationAttributesUpdateDTO {
    return RoofColorCustomizationAttributesUpdateDTOFromJSONTyped(json, false);
}

export function RoofColorCustomizationAttributesUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoofColorCustomizationAttributesUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': RoofColorCustomizationAttributesUpdateDTOValueFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesUpdateDTOValueFromJSON)),
    };
}

export function RoofColorCustomizationAttributesUpdateDTOToJSON(value?: RoofColorCustomizationAttributesUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': RoofColorCustomizationAttributesUpdateDTOValueToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesUpdateDTOValueToJSON)),
    };
}


