/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateOptionResponseDTO,
    CreateOptionResponseDTOFromJSON,
    CreateOptionResponseDTOToJSON,
    ExteriorColorCreateRequestDTO,
    ExteriorColorCreateRequestDTOFromJSON,
    ExteriorColorCreateRequestDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface CreateExteriorColorRequest {
    exteriorColorCreateRequestDTO: ExteriorColorCreateRequestDTO;
}

/**
 * 
 */
export class ExteriorColorCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new ExteriorColor
     */
    async createExteriorColorRaw(requestParameters: CreateExteriorColorRequest): Promise<runtime.ApiResponse<CreateOptionResponseDTO>> {
        if (requestParameters.exteriorColorCreateRequestDTO === null || requestParameters.exteriorColorCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('exteriorColorCreateRequestDTO','Required parameter requestParameters.exteriorColorCreateRequestDTO was null or undefined when calling createExteriorColor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/exterior-color/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExteriorColorCreateRequestDTOToJSON(requestParameters.exteriorColorCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOptionResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new ExteriorColor
     */
    async createExteriorColor(requestParameters: CreateExteriorColorRequest): Promise<CreateOptionResponseDTO> {
        const response = await this.createExteriorColorRaw(requestParameters);
        return await response.value();
    }

}
