import { interiorCustomizationRoutes } from "../../../../../common/routes"
import { NameItem } from "../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { LinkedCustomizationCard } from "../../../../../components/linkedcustomizationcard/LinkedCustomizationCard"
import { LocalizationsItemDTO, OptionLocalizationItemDTO } from "../../../../../generated/pdsapi"
import { interiorApi } from "../../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDerivedLocalizationCard } from "../../../../../viewtemplates/details/cards/derivedlocalizationcard/DetailsDerivedLocalizationCard"
import { DetailsLocalizationCard } from "../../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { LinkedInteriorCustomizationCardContent } from "./data/linkedcustomization/LinkedInteriorCustomizationCardContent"
import { InteriorOptionCardContent } from "./data/option/InteriorOptionCardContent"
import { InteriorSimilarCustomizationsCard } from "./data/similarcustomizations/InteriorSimilarCustomizationsCard"
import { OptionMarketplaceSpecificsCardContent } from "../../../../../components/optionmarketplacespecificscard/content/OptionMarketplaceSpecificsCardContent"

const nameItem = (label: string | undefined, item: LocalizationsItemDTO): NameItem => ({
    label,
    isDerived: item.raw === undefined,
    value: item.withFallback,
})

export const InteriorProductDetailsContainerConfig: ProductDetailsContainerConfig<{ orderTypeKey: string; optionCode: string }> = {
    getBaseInformation: interiorApi.details.getBaseInformation,

    deleteByKey: interiorApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Option"}
                        getData={interiorApi.details.getOption}
                        updateData={(data) =>
                            interiorApi.details.updateOption({
                                orderTypeKey: data.orderTypeKey,
                                optionCode: data.optionCode,
                                interiorOptionUpdateDTO: data.update,
                            })
                        }
                        Content={InteriorOptionCardContent}
                    />
                    <LinkedCustomizationCard
                        {...props}
                        getLinkedCustomization={interiorApi.details.getLinkedCustomization}
                        CustomizationContent={LinkedInteriorCustomizationCardContent}
                        getCreateCustomizationRoute={interiorCustomizationRoutes.create}
                    />
                    <InteriorSimilarCustomizationsCard {...props} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsDerivedLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={interiorApi.details.getName}
                        updateData={({ orderTypeKey, optionCode, update }) =>
                            interiorApi.details.updateName({
                                orderTypeKey,
                                optionCode,
                                requestBody: update,
                            })
                        }
                        getViewProps={(data, languageTag) => {
                            const name: OptionLocalizationItemDTO = data[languageTag]
                            return {
                                primaryName: nameItem(undefined, name.merged),
                                secondaryNames: [nameItem("Option", name.option), nameItem("Customization", name.customization ?? {})],
                            }
                        }}
                        getEditProps={(data, languageTag) => ({
                            rawName: data[languageTag].option.raw,
                        })}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard {...props} label={"Localizations"} getData={interiorApi.details.getDescription} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Market Specifics",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        isDataEditable={false}
                        urlPathParamsPrefix={props.urlPathParamsPrefix}
                        itemKey={props.itemKey}
                        reloadDataIndicator={props.reloadDataIndicator}
                        triggerReload={props.triggerReload}
                        label={"Market Specifics"}
                        getData={interiorApi.details.getMarketplaceSpecifics}
                        Content={OptionMarketplaceSpecificsCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

export const InteriorDetailsContainer = getProductDetailsContainer(InteriorProductDetailsContainerConfig)
