import {
    CustomizationVideoUpdateDTO,
    OptionCustomizationVideoLocalizationsDTO,
    UpdateVideoOfDefaultValueOfEquipmentCustomizationRequest,
    UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRequest,
} from "../../../../generated/pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../ProductDetailsContainer"
import { ProductDetailsTab } from "../ProductDetailsTab"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { OptionVideosCard } from "../../cards/optionvideoscard/OptionVideosCard"
import { TextSelector } from "../../../../components/selectors/textselector/TextSelector"
import { WidgetWrapper } from "../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { useToast } from "@finder/ui-kit"
import { stringConverterGroup, useURLSearchParamState } from "../../../../common/useURLSearchParamState"
import { showErrorToast } from "../../../../common/errorToastHelper"

export interface CustomizationVideosTabProps {
    getData: (props: { key: string }) => Promise<OptionCustomizationVideoLocalizationsDTO>
    updateDefaultValue: ({ key, customizationVideoUpdateDTO }: UpdateVideoOfDefaultValueOfEquipmentCustomizationRequest) => Promise<void>
    updateConditionalValue: ({
        key,
        customizationVideoUpdateDTO,
    }: UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRequest) => Promise<void>
}

export const CustomizationVideosTab: FC<ProductDetailsTabConfigContentProps & CustomizationVideosTabProps> = (props) => {
    const { itemKey, getData, updateDefaultValue, updateConditionalValue, urlPathParamsPrefix, reloadDataIndicator } = props

    const toastRef = useRef(useToast())
    const [conditionalValueKey, setConditionalValueKey] = useURLSearchParamState(
        `${urlPathParamsPrefix}.conditionalValueKey`,
        undefined,
        stringConverterGroup.optional
    )

    const [customizationVideoLocalizations, setCustomizationVideoLocalizations] = useState<OptionCustomizationVideoLocalizationsDTO | undefined>()

    const videoLocalizationsPromise = useMemo(() => getData(itemKey), [getData, itemKey, reloadDataIndicator])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setCustomizationVideoLocalizations(await videoLocalizationsPromise)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchData()
    }, [videoLocalizationsPromise])

    const getContentData = useCallback(
        () =>
            videoLocalizationsPromise.then((videoLocalizations) =>
                conditionalValueKey === undefined
                    ? videoLocalizations.defaultValue
                    : videoLocalizations.conditionalValues.find((conditionalValue) => conditionalValue.key === conditionalValueKey)!.value
            ),
        [videoLocalizationsPromise, conditionalValueKey]
    )

    const updateData = useCallback(
        ({ key, update }: { key: string; update: CustomizationVideoUpdateDTO }) =>
            conditionalValueKey === undefined
                ? updateDefaultValue({
                      key,
                      customizationVideoUpdateDTO: update,
                  })
                : updateConditionalValue({
                      key,
                      conditionalValueKey,
                      customizationVideoUpdateDTO: update,
                  }),
        [conditionalValueKey, updateDefaultValue, updateConditionalValue]
    )

    return (
        <ProductDetailsTab>
            <WidgetWrapper label={"Condition"}>
                <TextSelector
                    label={undefined}
                    options={[
                        {
                            value: "",
                            label: "Default Value",
                        },
                        ...(customizationVideoLocalizations?.conditionalValues.map((conditionalValue) => ({
                            value: conditionalValue.key,
                            label: `${conditionalValue.modelYearRange.start}-${conditionalValue.modelYearRange.end}`,
                        })) ?? []),
                    ]}
                    value={conditionalValueKey ?? ""}
                    onChange={(value) => setConditionalValueKey(value ? value : undefined)}
                />
            </WidgetWrapper>
            <OptionVideosCard {...props} label="Video" getData={getContentData} updateData={updateData} />
        </ProductDetailsTab>
    )
}
