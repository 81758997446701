import { DefaultFormElementLoading, EditLabeledContentWrapper, EditProps, FormElement, ViewProps } from "../FormElementWrapper"
import { TextFormElementView } from "../textformelement/TextFormElement"
import { MultiSelect } from "@finder/ui-kit"
import { SelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"
import { Controller, FieldValues } from "react-hook-form"

export const getMultiSelectTextFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string[],
    MultiSelectTextFormElementViewProps,
    MultiSelectTextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: MultiSelectTextFormElementView,
    Edit: MultiSelectTextFormElementEdit,
})

export interface MultiSelectTextFormElementViewProps {
    emptyLabel: string

    texts: string[]

    showAsHint?: boolean
}

export const MultiSelectTextFormElementView = ({ label, emptyLabel, texts, showAsHint }: ViewProps<MultiSelectTextFormElementViewProps>) => {
    const text = texts.length === 0 ? emptyLabel : texts.join(", ")
    return <TextFormElementView label={label} value={text} showAsHint={showAsHint} />
}

export interface MultiSelectTextFormElementEditProps {
    emptyLabel: string

    options: SelectOption[]
    value: string[]

    optional?: boolean
    showAsHint?: boolean
    onChange?: (value: string[]) => void
    clearable: boolean
    readOnly?: boolean
}

export const MultiSelectTextFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    control,
    validationErrors,
    clearable,
    emptyLabel,
    field,
    onChange,
    options,
    readOnly,
    optional,
    showAsHint,
    value,
}: EditProps<DATA, UPDATE, string[], MultiSelectTextFormElementEditProps>) => {
    const errorMessage = validationErrors.get(field._path)
    return (
        <EditLabeledContentWrapper label={label} optional={optional}>
            <Controller
                control={control}
                name={field._path}
                defaultValue={value as any}
                rules={{ required: !optional }}
                render={({ field }) => (
                    <MultiSelect
                        options={options}
                        value={field.value as any}
                        onChange={(newValue) => {
                            field.onChange(newValue)
                            onChange && onChange(newValue)
                        }}
                        placeholder={emptyLabel}
                        clearable={clearable}
                        searchable={true}
                        disabled={readOnly || showAsHint}
                        state={errorMessage ? "error" : "none"}
                        message={errorMessage}
                    />
                )}
            />
        </EditLabeledContentWrapper>
    )
}
