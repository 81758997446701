/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    CustomizationLinksDTO,
    CustomizationLinksDTOFromJSON,
    CustomizationLinksDTOToJSON,
    CustomizationVideoUpdateDTO,
    CustomizationVideoUpdateDTOFromJSON,
    CustomizationVideoUpdateDTOToJSON,
    ExteriorColorCustomizationAttributesDTO,
    ExteriorColorCustomizationAttributesDTOFromJSON,
    ExteriorColorCustomizationAttributesDTOToJSON,
    ExteriorColorCustomizationAttributesUpdateDTO,
    ExteriorColorCustomizationAttributesUpdateDTOFromJSON,
    ExteriorColorCustomizationAttributesUpdateDTOToJSON,
    OptionCustomizationLocalizationsDTO,
    OptionCustomizationLocalizationsDTOFromJSON,
    OptionCustomizationLocalizationsDTOToJSON,
    OptionCustomizationVideoLocalizationsDTO,
    OptionCustomizationVideoLocalizationsDTOFromJSON,
    OptionCustomizationVideoLocalizationsDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResult,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models';

export interface DeleteExteriorColorCustomizationRequest {
    key: string;
}

export interface GetAttributesOfExteriorColorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfExteriorColorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetLinksOfExteriorColorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfExteriorColorCustomizationRequest {
    key: string;
}

export interface GetLocalizationsOfVideoOfExteriorColorCustomizationRequest {
    key: string;
}

export interface GetVehicleImagesOfExteriorColorCustomizationRequest {
    key: string;
    limit?: number;
}

export interface UpdateAttributesOfExteriorColorCustomizationRequest {
    key: string;
    exteriorColorCustomizationAttributesUpdateDTO: ExteriorColorCustomizationAttributesUpdateDTO;
}

export interface UpdateNameOfDefaultValueOfExteriorColorCustomizationRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateVideoOfDefaultValueOfExteriorColorCustomizationRequest {
    key: string;
    customizationVideoUpdateDTO: CustomizationVideoUpdateDTO;
}

export interface UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    customizationVideoUpdateDTO: CustomizationVideoUpdateDTO;
}

/**
 * 
 */
export class ExteriorColorCustomizationDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a ExteriorColorCustomization by a given key
     */
    async deleteExteriorColorCustomizationRaw(requestParameters: DeleteExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a ExteriorColorCustomization by a given key
     */
    async deleteExteriorColorCustomization(requestParameters: DeleteExteriorColorCustomizationRequest): Promise<void> {
        await this.deleteExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Get attributes of ExteriorColorCustomization with given key
     */
    async getAttributesOfExteriorColorCustomizationRaw(requestParameters: GetAttributesOfExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<ExteriorColorCustomizationAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfExteriorColorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExteriorColorCustomizationAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of ExteriorColorCustomization with given key
     */
    async getAttributesOfExteriorColorCustomization(requestParameters: GetAttributesOfExteriorColorCustomizationRequest): Promise<ExteriorColorCustomizationAttributesDTO> {
        const response = await this.getAttributesOfExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of ExteriorColorCustomization with given key
     */
    async getBaseInformationOfExteriorColorCustomizationRaw(requestParameters: GetBaseInformationOfExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfExteriorColorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of ExteriorColorCustomization with given key
     */
    async getBaseInformationOfExteriorColorCustomization(requestParameters: GetBaseInformationOfExteriorColorCustomizationRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get links of ExteriorColorCustomization with given key
     */
    async getLinksOfExteriorColorCustomizationRaw(requestParameters: GetLinksOfExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<CustomizationLinksDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLinksOfExteriorColorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinksOfExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}/links`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomizationLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of ExteriorColorCustomization with given key
     */
    async getLinksOfExteriorColorCustomization(requestParameters: GetLinksOfExteriorColorCustomizationRequest): Promise<CustomizationLinksDTO> {
        const response = await this.getLinksOfExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of ExteriorColorCustomization with given key
     */
    async getLocalizationsOfNameOfExteriorColorCustomizationRaw(requestParameters: GetLocalizationsOfNameOfExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfName of ExteriorColorCustomization with given key
     */
    async getLocalizationsOfNameOfExteriorColorCustomization(requestParameters: GetLocalizationsOfNameOfExteriorColorCustomizationRequest): Promise<OptionCustomizationLocalizationsDTO> {
        const response = await this.getLocalizationsOfNameOfExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfVideo of ExteriorColorCustomization with given key
     */
    async getLocalizationsOfVideoOfExteriorColorCustomizationRaw(requestParameters: GetLocalizationsOfVideoOfExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationVideoLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfVideoOfExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}/video/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationVideoLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfVideo of ExteriorColorCustomization with given key
     */
    async getLocalizationsOfVideoOfExteriorColorCustomization(requestParameters: GetLocalizationsOfVideoOfExteriorColorCustomizationRequest): Promise<OptionCustomizationVideoLocalizationsDTO> {
        const response = await this.getLocalizationsOfVideoOfExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get vehicleImages of ExteriorColorCustomization with given key
     */
    async getVehicleImagesOfExteriorColorCustomizationRaw(requestParameters: GetVehicleImagesOfExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getVehicleImagesOfExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}/vehicle-images`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     * Get vehicleImages of ExteriorColorCustomization with given key
     */
    async getVehicleImagesOfExteriorColorCustomization(requestParameters: GetVehicleImagesOfExteriorColorCustomizationRequest): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.getVehicleImagesOfExteriorColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing ExteriorColorCustomization
     */
    async updateAttributesOfExteriorColorCustomizationRaw(requestParameters: UpdateAttributesOfExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfExteriorColorCustomization.');
        }

        if (requestParameters.exteriorColorCustomizationAttributesUpdateDTO === null || requestParameters.exteriorColorCustomizationAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('exteriorColorCustomizationAttributesUpdateDTO','Required parameter requestParameters.exteriorColorCustomizationAttributesUpdateDTO was null or undefined when calling updateAttributesOfExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExteriorColorCustomizationAttributesUpdateDTOToJSON(requestParameters.exteriorColorCustomizationAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing ExteriorColorCustomization
     */
    async updateAttributesOfExteriorColorCustomization(requestParameters: UpdateAttributesOfExteriorColorCustomizationRequest): Promise<void> {
        await this.updateAttributesOfExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfDefaultValue of an existing ExteriorColorCustomization
     */
    async updateNameOfDefaultValueOfExteriorColorCustomizationRaw(requestParameters: UpdateNameOfDefaultValueOfExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfDefaultValueOfExteriorColorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfDefaultValueOfExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}/default-value/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfDefaultValue of an existing ExteriorColorCustomization
     */
    async updateNameOfDefaultValueOfExteriorColorCustomization(requestParameters: UpdateNameOfDefaultValueOfExteriorColorCustomizationRequest): Promise<void> {
        await this.updateNameOfDefaultValueOfExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing ExteriorColorCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomizationRaw(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing ExteriorColorCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomization(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomizationRequest): Promise<void> {
        await this.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates videoOfDefaultValue of an existing ExteriorColorCustomization
     */
    async updateVideoOfDefaultValueOfExteriorColorCustomizationRaw(requestParameters: UpdateVideoOfDefaultValueOfExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateVideoOfDefaultValueOfExteriorColorCustomization.');
        }

        if (requestParameters.customizationVideoUpdateDTO === null || requestParameters.customizationVideoUpdateDTO === undefined) {
            throw new runtime.RequiredError('customizationVideoUpdateDTO','Required parameter requestParameters.customizationVideoUpdateDTO was null or undefined when calling updateVideoOfDefaultValueOfExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}/default-value/video`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomizationVideoUpdateDTOToJSON(requestParameters.customizationVideoUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates videoOfDefaultValue of an existing ExteriorColorCustomization
     */
    async updateVideoOfDefaultValueOfExteriorColorCustomization(requestParameters: UpdateVideoOfDefaultValueOfExteriorColorCustomizationRequest): Promise<void> {
        await this.updateVideoOfDefaultValueOfExteriorColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates videoOfValueOfConditionalValuesByConditionalValueKey of an existing ExteriorColorCustomization
     */
    async updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomizationRaw(requestParameters: UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomization.');
        }

        if (requestParameters.customizationVideoUpdateDTO === null || requestParameters.customizationVideoUpdateDTO === undefined) {
            throw new runtime.RequiredError('customizationVideoUpdateDTO','Required parameter requestParameters.customizationVideoUpdateDTO was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/exterior-color-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/video`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomizationVideoUpdateDTOToJSON(requestParameters.customizationVideoUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates videoOfValueOfConditionalValuesByConditionalValueKey of an existing ExteriorColorCustomization
     */
    async updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomization(requestParameters: UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomizationRequest): Promise<void> {
        await this.updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfExteriorColorCustomizationRaw(requestParameters);
    }

}
