/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    InteriorCustomizationAttributesUpdateDTOValueColor,
    InteriorCustomizationAttributesUpdateDTOValueColorFromJSON,
    InteriorCustomizationAttributesUpdateDTOValueColorFromJSONTyped,
    InteriorCustomizationAttributesUpdateDTOValueColorToJSON,
    InteriorMaterial,
    InteriorMaterialFromJSON,
    InteriorMaterialFromJSONTyped,
    InteriorMaterialToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorCustomizationAttributesUpdateDTOValue
 */
export interface InteriorCustomizationAttributesUpdateDTOValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof InteriorCustomizationAttributesUpdateDTOValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {InteriorCustomizationAttributesUpdateDTOValueColor}
     * @memberof InteriorCustomizationAttributesUpdateDTOValue
     */
    color: InteriorCustomizationAttributesUpdateDTOValueColor;
    /**
     * 
     * @type {InteriorMaterial}
     * @memberof InteriorCustomizationAttributesUpdateDTOValue
     */
    material: InteriorMaterial;
}

export function InteriorCustomizationAttributesUpdateDTOValueFromJSON(json: any): InteriorCustomizationAttributesUpdateDTOValue {
    return InteriorCustomizationAttributesUpdateDTOValueFromJSONTyped(json, false);
}

export function InteriorCustomizationAttributesUpdateDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationAttributesUpdateDTOValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'color': InteriorCustomizationAttributesUpdateDTOValueColorFromJSON(json['color']),
        'material': InteriorMaterialFromJSON(json['material']),
    };
}

export function InteriorCustomizationAttributesUpdateDTOValueToJSON(value?: InteriorCustomizationAttributesUpdateDTOValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'color': InteriorCustomizationAttributesUpdateDTOValueColorToJSON(value.color),
        'material': InteriorMaterialToJSON(value.material),
    };
}


