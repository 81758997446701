import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { OrderTypeItemDTO, OrderTypeSortField } from "../../../../../generated/pdsapi"
import { activeColumn } from "../../../../../viewtemplates/list/columns/activeColumn"

type OrderTypeColumnBuilder = ColumnBuilder<OrderTypeItemDTO, OrderTypeSortField>

export const OrderTypeListColumnsConfig: TableColumnsConfig<OrderTypeItemDTO, OrderTypeSortField> = ({ sorting, setSorting }) => {
    const codesColumn: OrderTypeColumnBuilder = {
        width: 70,
        headerCellProps: {
            content: "Codes",
        },
        buildCellContent: (orderType) => Array.from(orderType.codes).join(", "),
    }
    const modelYearColumn: OrderTypeColumnBuilder = {
        width: 60,
        headerCellProps: {
            content: "Model Year",
            sorting: {
                field: OrderTypeSortField.MODEL_YEAR,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (orderType) => orderType.modelYear.toString(),
    }
    const nameColumn: OrderTypeColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (orderType) => orderType.name ?? "-",
    }
    const modelGenerationColumn: OrderTypeColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Model Generation",
        },
        buildCellContent: (orderType) => orderType.modelGenerationName ?? "-",
    }
    const derivativeColumn: OrderTypeColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Derivative",
        },
        buildCellContent: (orderType) => orderType.derivativeName ?? "-",
    }

    return [codesColumn, modelYearColumn, nameColumn, modelGenerationColumn, derivativeColumn, activeColumn]
}
