import { derivativeRoutes } from "../../../../common/routes"
import { booleanConverterGroup, getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { BodyType, DerivativeFilterOptionsDTO, DerivativeItemDTO, SortDirection } from "../../../../generated/pdsapi"
import { derivativeApi, DerivativeFilter, DerivativeSortField, DerivativeSortFieldEnum } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { DerivativeListColumnsConfig } from "./columns/DerivativeListColumns"
import { DerivativeListFilter } from "./filter/DerivativeListFilter"
import { DerivativeListFilterTagsConfig } from "./filter/DerivativeListFilterTagsConfig"

export const DerivativeProductListContainerConfig: ProductListContainerConfig<
    DerivativeItemDTO,
    DerivativeFilter,
    DerivativeFilterOptionsDTO,
    DerivativeSortFieldEnum
> = {
    listName: "Derivatives",
    createItemLabel: "Add new Derivative",

    buildDetailsPath: (derivative) => derivativeRoutes.details(derivative.key),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<DerivativeFilter>({
        active: booleanConverterGroup.optional,
        bodyTypes: getEnumConverterGroup(BodyType).optionalArray,
        derivativeCategoryKeys: stringConverterGroup.optionalArray,
        modelSeriesKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: DerivativeSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(DerivativeSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: derivativeApi.list.getPage,
    fetchFilterOptions: derivativeApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: DerivativeListFilter,
    filterTagsConfig: DerivativeListFilterTagsConfig,

    columnsConfig: DerivativeListColumnsConfig,
}

export const DerivativeListContainer = getProductListContainer(DerivativeProductListContainerConfig)
