/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionalSelectDTOString,
    OptionalSelectDTOStringFromJSON,
    OptionalSelectDTOStringFromJSONTyped,
    OptionalSelectDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface InteriorCustomizationAttributesDTOValueColor
 */
export interface InteriorCustomizationAttributesDTOValueColor {
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesDTOValueColor
     */
    seatColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesDTOValueColor
     */
    seatStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesDTOValueColor
     */
    cockpitColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorCustomizationAttributesDTOValueColor
     */
    cockpitStitchingColorHexCode: string;
    /**
     * 
     * @type {OptionalSelectDTOString}
     * @memberof InteriorCustomizationAttributesDTOValueColor
     */
    tag: OptionalSelectDTOString;
    /**
     * 
     * @type {boolean}
     * @memberof InteriorCustomizationAttributesDTOValueColor
     */
    isCustomColor: boolean;
}

export function InteriorCustomizationAttributesDTOValueColorFromJSON(json: any): InteriorCustomizationAttributesDTOValueColor {
    return InteriorCustomizationAttributesDTOValueColorFromJSONTyped(json, false);
}

export function InteriorCustomizationAttributesDTOValueColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorCustomizationAttributesDTOValueColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seatColorHexCode': json['seatColorHexCode'],
        'seatStitchingColorHexCode': json['seatStitchingColorHexCode'],
        'cockpitColorHexCode': json['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': json['cockpitStitchingColorHexCode'],
        'tag': OptionalSelectDTOStringFromJSON(json['tag']),
        'isCustomColor': json['isCustomColor'],
    };
}

export function InteriorCustomizationAttributesDTOValueColorToJSON(value?: InteriorCustomizationAttributesDTOValueColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seatColorHexCode': value.seatColorHexCode,
        'seatStitchingColorHexCode': value.seatStitchingColorHexCode,
        'cockpitColorHexCode': value.cockpitColorHexCode,
        'cockpitStitchingColorHexCode': value.cockpitStitchingColorHexCode,
        'tag': OptionalSelectDTOStringToJSON(value.tag),
        'isCustomColor': value.isCustomColor,
    };
}


