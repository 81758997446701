import { ViewState } from "../../common/ViewState"
import { buildImageUrl } from "../../common/buildImageUrl"
import { VehicleStateSearchResult } from "../../generated/pdsapi"
import { Accordion } from "../accordion/Accordion"
import { EmptyView } from "../emptyview/EmptyView"
import { SpacedItems } from "../layouts/spaceditems/SpacedItems"
import { useWidgetWrapperContext } from "../layouts/widgetwrapper/WidgetWrapper"
import { PButtonPure, PInlineNotification } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { useEffect, useState } from "react"

interface LoadableVehicleImagesCardContentProps {
    shouldLoad?: boolean
    isInitiallyExpanded?: boolean
    load: (() => Promise<VehicleStateSearchResult[]>) | undefined
}

interface VehicleWithImages {
    vehicleIdentifier: string
    inventoryId: string
    urls: string[]
}

export const LoadableVehicleImagesCardContent = ({ load, shouldLoad: initialShouldLoad, isInitiallyExpanded }: LoadableVehicleImagesCardContentProps) => {
    const [shouldLoad, setShouldLoad] = useState(initialShouldLoad ?? false)
    const [vehiclesWithImages, setVehiclesWithImages] = useState<VehicleWithImages[]>()
    const [error, setError] = useState<boolean>(false)

    useEffect(() => {
        setError(false)

        shouldLoad &&
            load &&
            load()
                .then((results) => {
                    setVehiclesWithImages(
                        results.map((searchResult) => ({
                            vehicleIdentifier: searchResult.vehicleIdentifier,
                            inventoryId: searchResult.inventoryId,
                            urls: searchResult.vehicleImageIds.map((imageId) => buildImageUrl(imageId)),
                        }))
                    )
                })
                .catch(() => setError(true))
    }, [shouldLoad, load])

    const {
        buttons: [, setButtons],
    } = useWidgetWrapperContext()
    useEffect(() => {
        setButtons([
            <PButtonPure
                key={"load-or-close"}
                type={"button"}
                icon={shouldLoad ? "close" : "download"}
                weight={"regular"}
                onClick={() => setShouldLoad((old) => !old)}
                disabled={load === undefined}
            >
                {shouldLoad ? "Close" : "Load"}
            </PButtonPure>,
        ])
    }, [load, shouldLoad, setButtons])

    return shouldLoad ? (
        <SpacedItems direction={"column"} lineSpacing={spacing[16]}>
            {vehiclesWithImages?.length && vehiclesWithImages.every((vehicleWithImages) => vehicleWithImages.urls.length === 0) ? (
                <PInlineNotification
                    state={"warning"}
                    heading={"Vehicles found but no images"}
                    description={"There were vehicles but no images found. It might be that there are other vehicles that have images. Please contact us!"}
                />
            ) : null}
            {vehiclesWithImages?.length ? (
                <Accordion
                    initiallyExpandedIndex={-1}
                    expandAll={isInitiallyExpanded ?? false}
                    urlParamsPrefix={"vehicleImages"}
                    items={
                        vehiclesWithImages?.map((vehicleWithImages) => ({
                            label: `${vehicleWithImages.vehicleIdentifier} / Inventory ID: ${vehicleWithImages.inventoryId}`,
                            children: vehicleWithImages.urls.map((url, index) => (
                                <img key={`vehicle_${index}`} src={url} width="100%" alt={`Vehicle ${index + 1}`} />
                            )),
                        })) ?? []
                    }
                />
            ) : (
                <EmptyView viewState={error ? ViewState.ERROR : vehiclesWithImages ? ViewState.CONTENT : ViewState.LOADING} />
            )}
        </SpacedItems>
    ) : null
}
