/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MultiSelectDTOString,
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesDTOValue,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesDTOValueFromJSON,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesDTOValueFromJSONTyped,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesDTOValueToJSON,
    RoofColorCustomizationAttributesDTOValue,
    RoofColorCustomizationAttributesDTOValueFromJSON,
    RoofColorCustomizationAttributesDTOValueFromJSONTyped,
    RoofColorCustomizationAttributesDTOValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoofColorCustomizationAttributesDTO
 */
export interface RoofColorCustomizationAttributesDTO {
    /**
     * 
     * @type {string}
     * @memberof RoofColorCustomizationAttributesDTO
     */
    optionCode: string;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof RoofColorCustomizationAttributesDTO
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {boolean}
     * @memberof RoofColorCustomizationAttributesDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {RoofColorCustomizationAttributesDTOValue}
     * @memberof RoofColorCustomizationAttributesDTO
     */
    defaultValue: RoofColorCustomizationAttributesDTOValue;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesDTOValue>}
     * @memberof RoofColorCustomizationAttributesDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesDTOValue>;
}

export function RoofColorCustomizationAttributesDTOFromJSON(json: any): RoofColorCustomizationAttributesDTO {
    return RoofColorCustomizationAttributesDTOFromJSONTyped(json, false);
}

export function RoofColorCustomizationAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoofColorCustomizationAttributesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': RoofColorCustomizationAttributesDTOValueFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesDTOValueFromJSON)),
    };
}

export function RoofColorCustomizationAttributesDTOToJSON(value?: RoofColorCustomizationAttributesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerations': MultiSelectDTOStringToJSON(value.modelGenerations),
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': RoofColorCustomizationAttributesDTOValueToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesDTOValueToJSON)),
    };
}


