import { propertiesOf } from "../../../../../../common/property"
import { derivativeRoutes, modelGenerationRoutes, orderTypeRoutes } from "../../../../../../common/routes"
import { getSimpleFormWatch } from "../../../../../../components/formelements/FormDisplayData"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { ReadonlyFormElementWrapper } from "../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { getDerivedNameFormElement } from "../../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { toGroupedOption } from "../../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { getGroupedTagFormElement } from "../../../../../../components/formelements/groupedtagformelement/GroupedTagFormElement"
import { getMultiTextFormElement } from "../../../../../../components/formelements/multitextformelement/MultiTextFormElement"
import { getNumberFormElement } from "../../../../../../components/formelements/numberformelement/NumberFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTagFormElement } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { getTagsFormElement } from "../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO } from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { ActiveFormElement } from "../../../../../../viewtemplates/details/cards/components/activeformelement/ActiveFormElement"
import { useOrderTypeDetailsContext } from "../../OrderTypeDetailsContext"
import { FC, useMemo } from "react"

export const OrderTypeDetailsAttributesCardContent: FC<ProductDetailsCardContentProps<OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO>> = ({
    formDisplayData,
}) => {
    const { isEditable } = useOrderTypeDetailsContext()

    const properties = propertiesOf<OrderTypeAttributesUpdateDTO>()
    const watch = getSimpleFormWatch(formDisplayData)
    const data = formDisplayData.data
    const modelGenerationKey = watch("modelGenerationKey") || data?.modelSeriesRelatedModelGeneration.value?.key
    const modelSeriesKey = useMemo(
        () =>
            data?.modelSeriesRelatedModelGeneration.relatedCategorizedOptions.find(
                (modelSeriesGroup) => modelSeriesGroup.options.find((option) => option.key === modelGenerationKey) !== undefined
            )?.relatedKey,
        [modelGenerationKey, data]
    )

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Codes"}
                    formDisplayData={formDisplayData}
                    field={properties.codes}
                    FormElement={getMultiTextFormElement<OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.codes,
                    })}
                    getEditProps={(data) => ({
                        value: data.codes,
                        readOnly: !isEditable,
                    })}
                />
                <FormElementWrapper
                    label={"Model Year"}
                    formDisplayData={formDisplayData}
                    field={properties.modelYear}
                    FormElement={getNumberFormElement<OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.modelYear,
                    })}
                    getEditProps={(data) => ({
                        value: data.modelYear,
                        readOnly: !isEditable,
                    })}
                />
                <ReadonlyFormElementWrapper
                    label={"Other Model Years"}
                    formDisplayData={formDisplayData}
                    FormElement={getTagsFormElement()}
                    getViewProps={(data) => ({
                        emptyLabel: "-",
                        tags: data.furtherModelYears.map((option) => toTagOption(option, orderTypeRoutes.details)),
                    })}
                />
            </RowGroupWrapper>

            <RowGroupWrapper>
                <FormElementWrapper
                    label={"Model Generation"}
                    formDisplayData={formDisplayData}
                    field={properties.modelGenerationKey}
                    FormElement={getGroupedTagFormElement<OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        tag:
                            data.modelSeriesRelatedModelGeneration.value &&
                            toTagOption(data.modelSeriesRelatedModelGeneration.value, modelGenerationRoutes.details),
                    })}
                    getEditProps={(data) => ({
                        value: data.modelSeriesRelatedModelGeneration.value?.key ?? "",
                        options: data.modelSeriesRelatedModelGeneration.relatedCategorizedOptions.map(toGroupedOption),
                    })}
                />
                <FormElementWrapper
                    label={"Derivative"}
                    formDisplayData={formDisplayData}
                    field={properties.derivativeKey}
                    FormElement={getTagFormElement<OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        tag: data.modelSeriesRelatedDerivative.value && toTagOption(data.modelSeriesRelatedDerivative.value, derivativeRoutes.details),
                    })}
                    getEditProps={(data) => ({
                        value: data.modelSeriesRelatedDerivative.value?.key ?? "",
                        options: data.modelSeriesRelatedDerivative.relatedOptions
                            .filter((modelSeriesRelatedOption) => modelSeriesRelatedOption.relatedKey === modelSeriesKey)
                            .map(toOption),
                    })}
                />
                <ReadonlyFormElementWrapper
                    label={"Data Source"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.dataSource,
                    })}
                />
            </RowGroupWrapper>

            <RowGroupWrapper>
                <FormElementWrapper
                    label={"Drive Train"}
                    formDisplayData={formDisplayData}
                    field={properties.driveTrain}
                    FormElement={getSelectTextFormElement<OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: data.driveTrain.value?.label ?? "-",
                    })}
                    getEditProps={(data) => ({
                        value: data.driveTrain.value?.key ?? "",
                        options: [{ value: "", label: "-" }, ...data.driveTrain.options.map(toOption)],
                    })}
                />
                <FormElementWrapper
                    label={"Engine Type"}
                    formDisplayData={formDisplayData}
                    field={properties.engineType}
                    FormElement={getSelectTextFormElement<OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: data.engineType.value?.label ?? "-",
                    })}
                    getEditProps={(data) => ({
                        value: data.engineType.value?.key ?? "",
                        options: [{ value: "", label: "-" }, ...data.engineType.options.map(toOption)],
                    })}
                />
                <FormElementWrapper
                    label={"Number of Seats"}
                    formDisplayData={formDisplayData}
                    field={properties.numberOfSeats}
                    FormElement={getSelectTextFormElement<OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: data.numberOfSeats.value?.label ?? "-",
                    })}
                    getEditProps={(data) => ({
                        value: data.numberOfSeats.value?.key ?? "",
                        options: [{ value: "", label: "-" }, ...data.numberOfSeats.options.map(toOption)],
                    })}
                />
            </RowGroupWrapper>

            <RowGroupWrapper label={"Name"}>
                {(["de", "en"] as ("en" | "de")[]).map((languageTag) => (
                    <FormElementWrapper
                        key={languageTag}
                        label={`Name - ${languageTag}`}
                        formDisplayData={formDisplayData}
                        field={properties.manualName[languageTag]}
                        FormElement={getDerivedNameFormElement<OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO>()}
                        getViewProps={(data) => {
                            const name = data.name[languageTag]
                            return {
                                primaryName: {
                                    value: name.merged.withFallback,
                                    isDerived: !name.merged.raw,
                                },
                                secondaryNames: [
                                    {
                                        label: "Manual",
                                        value: name.manual.withFallback,
                                        isDerived: !name.manual.raw,
                                    },
                                    {
                                        label: "Synced",
                                        value: name.synced.withFallback,
                                        isDerived: !name.synced.raw,
                                    },
                                ],
                            }
                        }}
                        getEditProps={(data) => ({
                            rawName: data.name[languageTag].manual.raw,
                        })}
                    />
                ))}
            </RowGroupWrapper>

            <RowGroupWrapper label={"Status"}>
                <ActiveFormElement formDisplayData={formDisplayData} field={properties.active} getActive={(data) => data.active} />
                <FormElementWrapper
                    label={"Special"}
                    formDisplayData={formDisplayData}
                    field={properties.special}
                    FormElement={getBoolFormElement<OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.special,
                        trueMessage: "Yes",
                        falseMessage: "No",
                    })}
                    getEditProps={(data) => ({
                        value: data.special,
                        trueMessage: "Yes",
                        falseMessage: "No",
                    })}
                />
                <FormElementWrapper
                    label={"Limited"}
                    formDisplayData={formDisplayData}
                    field={properties.limited}
                    FormElement={getBoolFormElement<OrderTypeAttributesDTO, OrderTypeAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.limited,
                        trueMessage: "Yes",
                        falseMessage: "No",
                    })}
                    getEditProps={(data) => ({
                        value: data.limited,
                        trueMessage: "Yes",
                        falseMessage: "No",
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
