import { getEnumKeyByEnumValue } from "../../../../../common/global"
import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ActiveFilter } from "../../../../../components/filteredpagedtable/filter/active/ActiveFilter"
import { toGroupedOption } from "../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { BodyType, DerivativeFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { DerivativeFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const DerivativeListFilter: FunctionComponent<TableFilterProps<DerivativeFilter, DerivativeFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const modelSeriesFilter = (
        <MultiSelect
            label={"Model Series"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelSeries?.map(toOption)}
            value={filter.modelSeriesKeys ?? []}
            onChange={(modelSeriesKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelSeriesKeys: modelSeriesKeys.length === 0 ? undefined : modelSeriesKeys,
                }))
            }
        />
    )

    const relevantDerivativeCategories = filter.modelSeriesKeys
        ? filterOptions?.modelSeriesRelatedDerivativeCategories.filter((modelSeriesGroup) => filter.modelSeriesKeys!.includes(modelSeriesGroup.relatedKey))
        : filterOptions?.modelSeriesRelatedDerivativeCategories
    const derivativeCategoryFilter = (
        <MultiSelect
            label={"Derivative Category"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={relevantDerivativeCategories?.map(toGroupedOption)}
            value={filter.derivativeCategoryKeys ?? []}
            onChange={(derivativeCategoryKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    derivativeCategoryKeys: derivativeCategoryKeys.length === 0 ? undefined : derivativeCategoryKeys,
                }))
            }
        />
    )

    const bodyTypeFilter = (
        <MultiSelect
            label={"Body Types"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.bodyTypes?.map(toOption)}
            value={filter.bodyTypes ?? []}
            onChange={(bodyTypes) =>
                setFilter((filter) => ({
                    ...filter,
                    bodyTypes:
                        bodyTypes.length === 0 ? undefined : bodyTypes.map((bodyTypeString) => BodyType[getEnumKeyByEnumValue(BodyType, bodyTypeString)!]),
                }))
            }
        />
    )

    const activeFilter = (
        <ActiveFilter
            active={filter.active}
            onChange={(active) =>
                setFilter((filter) => ({
                    ...filter,
                    active,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>
                {modelSeriesFilter}
                {derivativeCategoryFilter}
                {bodyTypeFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Status"}>{activeFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
