import { derivativeCategoriesRoutes } from "../../../../common/routes"
import { booleanConverterGroup, getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { DerivativeCategoryFilterOptionsDTO, DerivativeCategoryItemDTO, SortDirection } from "../../../../generated/pdsapi"
import { derivativeCategoryApi, DerivativeCategoryFilter, DerivativeCategorySortField, DerivativeCategorySortFieldEnum } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { DerivativeCategoryListColumnsConfig } from "./columns/DerivativeCategoryListColumns"
import { DerivativeCategoryListFilter } from "./filter/DerivativeCategoryListFilter"
import { DerivativeCategoryListFilterTagsConfig } from "./filter/DerivativeCategoryListFilterTagsConfig"

export const DerivativeCategoryProductListContainerConfig: ProductListContainerConfig<
    DerivativeCategoryItemDTO,
    DerivativeCategoryFilter,
    DerivativeCategoryFilterOptionsDTO,
    DerivativeCategorySortFieldEnum
> = {
    listName: "Derivative Categories",
    createItemLabel: "Add new Derivative Category",

    buildDetailsPath: (derivativeCategory) => derivativeCategoriesRoutes.details(derivativeCategory.key),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<DerivativeCategoryFilter>({
        active: booleanConverterGroup.optional,
        modelSeriesKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: DerivativeCategorySortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(DerivativeCategorySortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: derivativeCategoryApi.list.getPage,
    fetchFilterOptions: derivativeCategoryApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: DerivativeCategoryListFilter,
    filterTagsConfig: DerivativeCategoryListFilterTagsConfig,

    columnsConfig: DerivativeCategoryListColumnsConfig,
}

export const DerivativeCategoryListContainer = getProductListContainer(DerivativeCategoryProductListContainerConfig)
