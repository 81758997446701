import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ActiveFilter } from "../../../../../components/filteredpagedtable/filter/active/ActiveFilter"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { DerivativeCategoryFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { DerivativeCategoryFilter } from "../../../../../pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { FunctionComponent } from "react"

export const DerivativeCategoryListFilter: FunctionComponent<TableFilterProps<DerivativeCategoryFilter, DerivativeCategoryFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const modelSeriesFilter = (
        <MultiSelect
            label={"Model Series"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.modelSeries?.map(toOption)}
            value={filter.modelSeriesKeys ?? []}
            onChange={(modelSeriesKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelSeriesKeys: modelSeriesKeys.length === 0 ? undefined : modelSeriesKeys,
                }))
            }
        />
    )

    const activeFilter = (
        <ActiveFilter
            active={filter.active}
            onChange={(active) =>
                setFilter((filter) => ({
                    ...filter,
                    active,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>{modelSeriesFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Status"}>{activeFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
