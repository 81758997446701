import { usePdsBootContext } from "../../../../../common/PdsBootContext"
import { Sorting } from "../../../../../common/Sorting"
import { getEnumConverterGroup, getObjectURLParamConverter, URLParamConverter } from "../../../../../common/useURLSearchParamState"
import { AccordionItem } from "../../../../../components/accordion/AccordionItem"
import {
    FilteredPagedTable,
    FilteredPagedTableFindParams,
    TableColumnsConfig,
    TableFilterProps,
    TableFilterTagsConfig,
} from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Box } from "../../../../../components/layouts/box/Box"
import { SortDirection, SortFieldCombinedEnum, UserAuthority } from "../../../../../generated/pdsapi"
import { useOrderTypeDetailsContext } from "../OrderTypeDetailsContext"
import { PButton } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, useCallback } from "react"
import { useNavigate } from "react-router-dom"

export interface OrderTypeOptionsCardProps<OPTION, FILTER, FILTER_OPTIONS> {
    label: string

    getCreateRoute: (orderTypeKey: string) => string
    getDetailsRoute: (orderTypeKey: string, optionCode: string) => string
    getOptions: (
        findParams: FilteredPagedTableFindParams<FILTER, SortFieldCombinedEnum> & { key: string }
    ) => Promise<{ totalItemCount: number; content: OPTION[] }>
    getFilterOptions: (params: { key: string; languageTag: string }) => Promise<FILTER_OPTIONS>

    defaultFilter: FILTER
    filterUrlParamsConverter: URLParamConverter<FILTER>

    columnsConfig: TableColumnsConfig<OPTION, SortFieldCombinedEnum>
    FilterComponent: FC<TableFilterProps<FILTER, FILTER_OPTIONS>>
    filterTagsConfig: TableFilterTagsConfig<FILTER, FILTER_OPTIONS>

    isInitiallyExpanded: boolean
}

export const OrderTypeOptionsCard = <OPTION extends { optionCode: string }, FILTER, FILTER_OPTIONS>({
    urlPathParamsPrefix,
    orderTypeKey,

    label,

    getCreateRoute,
    getDetailsRoute,
    getOptions,
    getFilterOptions,

    defaultFilter,
    filterUrlParamsConverter,

    columnsConfig,
    FilterComponent,
    filterTagsConfig,

    isInitiallyExpanded,
}: { urlPathParamsPrefix: string; orderTypeKey: string } & OrderTypeOptionsCardProps<OPTION, FILTER, FILTER_OPTIONS>) => {
    const navigate = useNavigate()
    const { hasAuthority } = usePdsBootContext()
    const { isEditable } = useOrderTypeDetailsContext()

    const createButton =
        isEditable && hasAuthority(UserAuthority.MAINTAINER) ? (
            <PButton key={"createButton"} icon={"plus"} onClick={() => navigate(getCreateRoute(orderTypeKey))}>
                Create
            </PButton>
        ) : undefined

    const fetchPage = useCallback(
        (findParams: FilteredPagedTableFindParams<FILTER, SortFieldCombinedEnum>) =>
            getOptions({
                key: orderTypeKey,
                ...findParams,
            }),
        [getOptions, orderTypeKey]
    )

    const fetchFilterOptions = useCallback(
        (params: { languageTag: string }) =>
            getFilterOptions({
                key: orderTypeKey,
                ...params,
            }),
        [getFilterOptions, orderTypeKey]
    )
    const buildDetailsPath = useCallback((option: OPTION) => getDetailsRoute(orderTypeKey, option.optionCode), [orderTypeKey, getDetailsRoute])

    return (
        <AccordionItem label={label} isInitiallyExpanded={isInitiallyExpanded} urlParamsPrefix={`${urlPathParamsPrefix}.accordion`} actions={createButton}>
            <Box height={spacing[32]} />
            <FilteredPagedTable
                itemsName={label}
                urlParamsPrefix={`${urlPathParamsPrefix}.list`}
                columnsConfig={columnsConfig}
                buildDetailsPath={buildDetailsPath}
                fetchPage={fetchPage}
                fetchFilterOptions={fetchFilterOptions}
                defaultFilter={defaultFilter}
                filterUrlParamsConverter={filterUrlParamsConverter}
                defaultSorting={{
                    sortField: SortFieldCombinedEnum.SORT_INDEX,
                    sortDirection: SortDirection.ASC,
                }}
                sortingUrlParamsConverter={getObjectURLParamConverter<Sorting<SortFieldCombinedEnum>>({
                    sortField: getEnumConverterGroup(SortFieldCombinedEnum).required,
                    sortDirection: getEnumConverterGroup(SortDirection).required,
                })}
                defaultShowsFilter={false}
                FilterComponent={FilterComponent}
                filterTagsConfig={filterTagsConfig}
            />
        </AccordionItem>
    )
}
