import { propertiesOf, Property } from "../../../../../../common/property"
import { interiorColorTagRoutes, modelGenerationRoutes } from "../../../../../../common/routes"
import { FormDisplayData, getEditData } from "../../../../../../components/formelements/FormDisplayData"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { getNumberFormElement } from "../../../../../../components/formelements/numberformelement/NumberFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTagFormElement } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { getTagsFormElement } from "../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    InteriorCustomizationAttributesDTO,
    InteriorCustomizationAttributesDTOValue,
    InteriorCustomizationAttributesUpdateDTO,
    InteriorCustomizationAttributesUpdateDTOValue,
    InteriorMaterial,
} from "../../../../../../generated/pdsapi"
import { useSyncState } from "../../../../../../hooks/useSyncState"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { InteriorColorTile } from "@finder/ui-kit"
import { PButtonPure, PDivider, PHeadline, PText } from "@porsche-design-system/components-react"
import ObjectID from "bson-objectid"
import { FC } from "react"

export const InteriorCustomizationDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>
> = ({ formDisplayData }) => {
    const interiorCustomizationProperties = propertiesOf<InteriorCustomizationAttributesUpdateDTO>()
    const [conditionalValues, setConditionalValues] = useSyncState(formDisplayData.data?.conditionalValues ?? [], {
        onChange: (conditionalValues) =>
            getEditData(formDisplayData)?.setValue(
                "conditionalValues",
                conditionalValues.map((conditionalValue) => ({
                    key: conditionalValue.key,
                    modelYearRange: conditionalValue.modelYearRange,
                    value: {
                        name: conditionalValue.value.name,
                        color: {
                            seatColorHexCode: conditionalValue.value.color.seatColorHexCode,
                            seatStitchingColorHexCode: conditionalValue.value.color.seatStitchingColorHexCode,
                            cockpitColorHexCode: conditionalValue.value.color.cockpitColorHexCode,
                            cockpitStitchingColorHexCode: conditionalValue.value.color.cockpitStitchingColorHexCode,
                            isCustomColor: conditionalValue.value.color.isCustomColor,
                            tagKey: conditionalValue.value.color.tag.value?.key,
                        },
                        material: (conditionalValue.value.material.value && conditionalValue.value.material.value.key) || InteriorMaterial.OTHERS,
                    },
                }))
            ),
    })

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={interiorCustomizationProperties.optionCode}
                    FormElement={getTextFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.optionCode,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.optionCode,
                    })}
                />
                <FormElementWrapper
                    label={"Model Generations"}
                    formDisplayData={formDisplayData}
                    field={interiorCustomizationProperties.modelGenerationKeys}
                    FormElement={getTagsFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        emptyLabel: "-",
                        tags: data.modelGenerations.value.map((modelGeneration) => toTagOption(modelGeneration, modelGenerationRoutes.details)),
                    })}
                    getEditProps={(data) => ({
                        emptyLabel: "-",
                        value: data.modelGenerations.value.map((modelGeneration) => modelGeneration.key),
                        options: data.modelGenerations.options.map(toOption),
                        clearable: true,
                    })}
                />
                <FormElementWrapper
                    label={"Default Value Status"}
                    formDisplayData={formDisplayData}
                    field={interiorCustomizationProperties.isDefaultValueActive}
                    FormElement={getBoolFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                    getEditProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                />
            </RowGroupWrapper>

            <PDivider />
            <>
                <PHeadline variant={"headline-4"}>Default Value</PHeadline>
                {conditionalValues.length ? (
                    <PText color={"neutral-contrast-medium"}>
                        Not applied in model years:{" "}
                        {conditionalValues
                            .map((conditionalValue) =>
                                conditionalValue.modelYearRange.start !== conditionalValue.modelYearRange.end
                                    ? `${conditionalValue.modelYearRange.start}-${conditionalValue.modelYearRange.end}`
                                    : conditionalValue.modelYearRange.start
                            )
                            .join(", ")}
                    </PText>
                ) : null}
            </>

            <CustomizationValueContent
                key={"defaultValue"}
                formDisplayData={formDisplayData}
                getValue={(attributes) => attributes.defaultValue}
                property={interiorCustomizationProperties.defaultValue}
            />

            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PDivider /> : null}
            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PHeadline variant={"headline-4"}>Conditional Values</PHeadline> : null}

            {conditionalValues.flatMap((conditionalValue, index) => {
                const property = interiorCustomizationProperties.conditionalValues._index(index)

                return [
                    <RowGroupWrapper key={`configuration_${index}`} label={"Configuration"}>
                        <FormElementWrapper
                            label={"Key"}
                            formDisplayData={formDisplayData}
                            field={property.key}
                            FormElement={getTextFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                            getViewProps={() => ({
                                value: conditionalValue.key,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.key,
                                readOnly: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <RowGroupWrapper key={`model_year_condition_${index}`} label={"Model Year Condition"}>
                        <FormElementWrapper
                            label={"From"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.start}
                            FormElement={getNumberFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange.start,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange.start,
                            })}
                        />
                        <FormElementWrapper
                            label={"To"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.end}
                            FormElement={getNumberFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange.end,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange.end,
                            })}
                        />
                    </RowGroupWrapper>,
                    <CustomizationValueContent
                        key={`customization_value_content_${index}`}
                        formDisplayData={formDisplayData}
                        getValue={() => conditionalValue.value}
                        property={property.value}
                    />,
                    formDisplayData.kind === "EDIT" ? (
                        <PButtonPure
                            key={`delete_conditional_value_${index}`}
                            type={"button"}
                            icon={"delete"}
                            onClick={() => {
                                setConditionalValues(conditionalValues.filter((_, i) => i !== index))
                            }}
                        >
                            Delete Conditional Value
                        </PButtonPure>
                    ) : null,
                    formDisplayData.kind === "EDIT" || index !== conditionalValues.length - 1 ? <PDivider key={`divider_${index}`} /> : null,
                ]
            })}
            {formDisplayData.kind === "EDIT" ? (
                <PButtonPure
                    type={"button"}
                    icon={"add"}
                    onClick={() =>
                        setConditionalValues((conditionalValues) => [
                            ...conditionalValues,
                            {
                                key: ObjectID().toHexString(),
                                modelYearRange: { start: 2020, end: 2020 },
                                value: formDisplayData.data.defaultValue,
                            },
                        ])
                    }
                >
                    Add Conditional Value
                </PButtonPure>
            ) : null}
        </SpacedItems>
    )
}

const CustomizationValueContent = ({
    getValue,
    property,
    formDisplayData,
}: {
    key: string
    getValue: (attributes: InteriorCustomizationAttributesDTO) => InteriorCustomizationAttributesDTOValue
    property: Property<InteriorCustomizationAttributesUpdateDTO, InteriorCustomizationAttributesUpdateDTOValue>
    formDisplayData: FormDisplayData<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>
}) => {
    const attributes = formDisplayData.data
    const value = attributes && getValue(attributes)
    const [seatColorHexCode, setSeatColorHexCode] = useSyncState(value?.color.seatColorHexCode)
    const [seatStitchingColorHexCode, setSeatStitchingColorHexCode] = useSyncState(value?.color.seatStitchingColorHexCode)
    const [cockpitColorHexCode, setCockpitColorHexCode] = useSyncState(value?.color.cockpitColorHexCode)
    const [cockpitStitchingColorHexCode, setCockpitStitchingColorHexCode] = useSyncState(value?.color.cockpitStitchingColorHexCode)

    const colorLabel = (
        <>
            Color
            <InteriorColorTile
                seatColor={seatColorHexCode ?? "#000000"}
                seatStitchingColor={seatStitchingColorHexCode}
                cockpitColor={cockpitColorHexCode}
                cockpitStitchingColor={cockpitStitchingColorHexCode}
            />
        </>
    )

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={property.name}
                getViewProps={(data) => ({
                    value: getValue(data).name,
                })}
                getEditProps={(data) => ({
                    value: getValue(data).name,
                    optional: true,
                })}
            />
            <RowGroupWrapper label={colorLabel}>
                <FormElementWrapper
                    label={"Seat Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.color.seatColorHexCode}
                    FormElement={getColorPickerFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        hexValue: getValue(data).color.seatColorHexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={(data) => ({
                        hexValue: getValue(data).color.seatColorHexCode,
                        disableGlossy: true,
                        onChange: setSeatColorHexCode,
                    })}
                />
                <FormElementWrapper
                    label={"Seat Stitching Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.color.seatStitchingColorHexCode}
                    FormElement={getColorPickerFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        hexValue: getValue(data).color.seatStitchingColorHexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={(data) => ({
                        hexValue: getValue(data).color.seatStitchingColorHexCode,
                        disableGlossy: true,
                        onChange: setSeatStitchingColorHexCode,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <FormElementWrapper
                    label={"Cockpit Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.color.cockpitColorHexCode}
                    FormElement={getColorPickerFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        hexValue: getValue(data).color.cockpitColorHexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={(data) => ({
                        hexValue: getValue(data).color.cockpitColorHexCode,
                        disableGlossy: true,
                        onChange: setCockpitColorHexCode,
                    })}
                />
                <FormElementWrapper
                    label={"Cockpit Stitching Color Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.color.cockpitStitchingColorHexCode}
                    FormElement={getColorPickerFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        hexValue: getValue(data).color.cockpitStitchingColorHexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={(data) => ({
                        hexValue: getValue(data).color.cockpitStitchingColorHexCode,
                        disableGlossy: true,
                        onChange: setCockpitStitchingColorHexCode,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <FormElementWrapper
                    label={"Interior Color Tag"}
                    formDisplayData={formDisplayData}
                    field={property.color.tagKey}
                    FormElement={getTagFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => {
                        const tag = getValue(data).color.tag.value
                        return {
                            tag: tag && toTagOption(tag, interiorColorTagRoutes.details),
                        }
                    }}
                    getEditProps={(data) => ({
                        value: getValue(data).color.tag.value?.key ?? "",
                        options: [
                            {
                                value: "",
                                label: "-",
                            },
                            ...getValue(data).color.tag.options.map((tag) => toOption(tag)),
                        ],
                        optional: true,
                    })}
                />
                <FormElementWrapper
                    label={"Custom Color"}
                    formDisplayData={formDisplayData}
                    field={property.color.isCustomColor}
                    FormElement={getBoolFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: getValue(data).color.isCustomColor,
                    })}
                    getEditProps={(data) => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: getValue(data).color.isCustomColor,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper label={"Material"}>
                <FormElementWrapper
                    label={"Interior Material"}
                    formDisplayData={formDisplayData}
                    field={property.material}
                    FormElement={getSelectTextFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: getValue(data).material.value.label,
                    })}
                    getEditProps={(data) => ({
                        value: getValue(data).material.value.key,
                        options: getValue(data).material.options.map(toOption),
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
