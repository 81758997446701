/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntRange,
    IntRangeFromJSON,
    IntRangeFromJSONTyped,
    IntRangeToJSON,
    VideoLocalizationsItemDTO,
    VideoLocalizationsItemDTOFromJSON,
    VideoLocalizationsItemDTOFromJSONTyped,
    VideoLocalizationsItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO
 */
export interface OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO
     */
    key: string;
    /**
     * 
     * @type {IntRange}
     * @memberof OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO
     */
    modelYearRange: IntRange;
    /**
     * 
     * @type {{ [key: string]: VideoLocalizationsItemDTO; }}
     * @memberof OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO
     */
    value: { [key: string]: VideoLocalizationsItemDTO; };
}

export function OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTOFromJSON(json: any): OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO {
    return OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTOFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': IntRangeFromJSON(json['modelYearRange']),
        'value': (mapValues(json['value'], VideoLocalizationsItemDTOFromJSON)),
    };
}

export function OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTOToJSON(value?: OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'modelYearRange': IntRangeToJSON(value.modelYearRange),
        'value': (mapValues(value.value, VideoLocalizationsItemDTOToJSON)),
    };
}


