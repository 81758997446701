import { DefaultFormElementLoading, EditLabeledContentWrapper, EditProps, FormElement, ViewLabeledContentWrapper, ViewProps } from "../FormElementWrapper"
import { PText, PTextFieldWrapper } from "@porsche-design-system/components-react"
import { ChangeEvent } from "react"
import { FieldValues } from "react-hook-form"

export const getTextFormElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string,
    TextFormElementViewProps,
    TextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: TextFormElementView,
    Edit: TextFormElementEdit,
})

export interface TextFormElementViewProps {
    value: string | undefined
    showAsHint?: boolean
}

export const TextFormElementView = ({ label, showAsHint, value }: ViewProps<TextFormElementViewProps>) => (
    <ViewLabeledContentWrapper label={label}>
        <PText color={showAsHint ? "neutral-contrast-low" : "default"}>{showAsHint ? `${value ?? "-"} *` : value ?? "-"}</PText>
    </ViewLabeledContentWrapper>
)

export interface TextFormElementEditProps {
    value: string

    onChange?: (value: string) => void

    showAsHint?: boolean
    readOnly?: boolean
    optional?: boolean
}

export const TextFormElementEdit = <DATA, UPDATE extends FieldValues>({
    label,
    field,
    onChange,
    optional,
    readOnly,
    register,
    showAsHint,
    validationErrors,
    value,
}: EditProps<DATA, UPDATE, string, TextFormElementEditProps>) => {
    const errorMessage = validationErrors.get(field?._path)
    const registered = register(field._path, { required: !optional })

    return (
        <EditLabeledContentWrapper label={label} optional={optional}>
            <PTextFieldWrapper state={errorMessage ? "error" : "none"} message={errorMessage}>
                <input
                    {...registered}
                    type={"text"}
                    defaultValue={showAsHint ? "" : value}
                    onChange={async (changeEvent: ChangeEvent<HTMLInputElement>) => {
                        await registered.onChange(changeEvent)
                        onChange && onChange(changeEvent.target.value)
                    }}
                    readOnly={readOnly}
                />
            </PTextFieldWrapper>
        </EditLabeledContentWrapper>
    )
}
