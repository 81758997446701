import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ActiveFilter } from "../../../../../components/filteredpagedtable/filter/active/ActiveFilter"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { ModelSeriesFilter } from "../../../../../pdsapi"
import { FunctionComponent } from "react"

export const ModelSeriesListFilter: FunctionComponent<TableFilterProps<ModelSeriesFilter, unknown>> = ({ filter, setFilter }) => {
    const activeFilter = (
        <ActiveFilter
            active={filter.active}
            onChange={(active) =>
                setFilter((filter) => ({
                    ...filter,
                    active,
                }))
            }
        />
    )
    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Status"}>{activeFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
