import { exteriorColorCustomizationRoutes } from "../../../../../common/routes"
import { NameItem } from "../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { LinkedCustomizationCard } from "../../../../../components/linkedcustomizationcard/LinkedCustomizationCard"
import { LocalizationsItemDTO, OptionLocalizationItemDTO } from "../../../../../generated/pdsapi"
import { exteriorColorApi } from "../../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDerivedLocalizationCard } from "../../../../../viewtemplates/details/cards/derivedlocalizationcard/DetailsDerivedLocalizationCard"
import { ProductDetailsTab } from "../../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { LinkedExteriorColorCustomizationCardContent } from "./data/linkedcustomization/LinkedExteriorColorCustomizationCardContent"
import { ExteriorColorOptionCardContent } from "./data/option/ExteriorColorOptionCardContent"
import { ExteriorColorSimilarCustomizationsCard } from "./data/similarcustomizations/ExteriorColorSimilarCustomizationsCard"
import { OptionMarketplaceSpecificsCardContent } from "../../../../../components/optionmarketplacespecificscard/content/OptionMarketplaceSpecificsCardContent"

const nameItem = (label: string | undefined, item: LocalizationsItemDTO): NameItem => ({
    label,
    isDerived: item.raw === undefined,
    value: item.withFallback,
})

export const ExteriorColorProductDetailsContainerConfig: ProductDetailsContainerConfig<{
    orderTypeKey: string
    optionCode: string
}> = {
    getBaseInformation: exteriorColorApi.details.getBaseInformation,

    deleteByKey: exteriorColorApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Option"}
                        getData={exteriorColorApi.details.getOption}
                        updateData={(data) =>
                            exteriorColorApi.details.updateOption({
                                orderTypeKey: data.orderTypeKey,
                                optionCode: data.optionCode,
                                exteriorColorOptionUpdateDTO: data.update,
                            })
                        }
                        Content={ExteriorColorOptionCardContent}
                    />
                    <LinkedCustomizationCard
                        {...props}
                        getLinkedCustomization={exteriorColorApi.details.getLinkedCustomization}
                        CustomizationContent={LinkedExteriorColorCustomizationCardContent}
                        getCreateCustomizationRoute={exteriorColorCustomizationRoutes.create}
                    />
                    <ExteriorColorSimilarCustomizationsCard {...props} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsDerivedLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={exteriorColorApi.details.getName}
                        updateData={({ orderTypeKey, optionCode, update }) =>
                            exteriorColorApi.details.updateName({
                                orderTypeKey,
                                optionCode,
                                requestBody: update,
                            })
                        }
                        getViewProps={(data, languageTag) => {
                            const name: OptionLocalizationItemDTO = data[languageTag]
                            return {
                                primaryName: nameItem(undefined, name.merged),
                                secondaryNames: [nameItem("Option", name.option), nameItem("Customization", name.customization ?? {})],
                            }
                        }}
                        getEditProps={(data, languageTag) => ({
                            rawName: data[languageTag].option.raw,
                        })}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Market Specifics",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        isDataEditable={false}
                        urlPathParamsPrefix={props.urlPathParamsPrefix}
                        itemKey={props.itemKey}
                        reloadDataIndicator={props.reloadDataIndicator}
                        triggerReload={props.triggerReload}
                        label={"Market Specifics"}
                        getData={exteriorColorApi.details.getMarketplaceSpecifics}
                        Content={OptionMarketplaceSpecificsCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

export const ExteriorColorDetailsContainer = getProductDetailsContainer(ExteriorColorProductDetailsContainerConfig)
