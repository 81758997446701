/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BodyType,
    BodyTypeFromJSON,
    BodyTypeFromJSONTyped,
    BodyTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface DerivativeAttributesUpdateDTO
 */
export interface DerivativeAttributesUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof DerivativeAttributesUpdateDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof DerivativeAttributesUpdateDTO
     */
    derivativeCategory: string;
    /**
     * 
     * @type {BodyType}
     * @memberof DerivativeAttributesUpdateDTO
     */
    bodyType: BodyType;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof DerivativeAttributesUpdateDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {boolean}
     * @memberof DerivativeAttributesUpdateDTO
     */
    active: boolean;
}

export function DerivativeAttributesUpdateDTOFromJSON(json: any): DerivativeAttributesUpdateDTO {
    return DerivativeAttributesUpdateDTOFromJSONTyped(json, false);
}

export function DerivativeAttributesUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeAttributesUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'derivativeCategory': json['derivativeCategory'],
        'bodyType': BodyTypeFromJSON(json['bodyType']),
        'name': BaseNameDTOFromJSON(json['name']),
        'active': json['active'],
    };
}

export function DerivativeAttributesUpdateDTOToJSON(value?: DerivativeAttributesUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'derivativeCategory': value.derivativeCategory,
        'bodyType': BodyTypeToJSON(value.bodyType),
        'name': BaseNameDTOToJSON(value.name),
        'active': value.active,
    };
}


