/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntRange,
    IntRangeFromJSON,
    IntRangeFromJSONTyped,
    IntRangeToJSON,
    InteriorCustomizationAttributesUpdateDTOValue,
    InteriorCustomizationAttributesUpdateDTOValueFromJSON,
    InteriorCustomizationAttributesUpdateDTOValueFromJSONTyped,
    InteriorCustomizationAttributesUpdateDTOValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValue
 */
export interface OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValue
     */
    key: string;
    /**
     * 
     * @type {IntRange}
     * @memberof OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValue
     */
    modelYearRange: IntRange;
    /**
     * 
     * @type {InteriorCustomizationAttributesUpdateDTOValue}
     * @memberof OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValue
     */
    value: InteriorCustomizationAttributesUpdateDTOValue;
}

export function OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValueFromJSON(json: any): OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValue {
    return OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': IntRangeFromJSON(json['modelYearRange']),
        'value': InteriorCustomizationAttributesUpdateDTOValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValueToJSON(value?: OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesUpdateDTOValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'modelYearRange': IntRangeToJSON(value.modelYearRange),
        'value': InteriorCustomizationAttributesUpdateDTOValueToJSON(value.value),
    };
}


