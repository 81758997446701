/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    CustomizationLinksDTO,
    CustomizationLinksDTOFromJSON,
    CustomizationLinksDTOToJSON,
    CustomizationVideoUpdateDTO,
    CustomizationVideoUpdateDTOFromJSON,
    CustomizationVideoUpdateDTOToJSON,
    EquipmentCustomizationAttributesDTO,
    EquipmentCustomizationAttributesDTOFromJSON,
    EquipmentCustomizationAttributesDTOToJSON,
    EquipmentCustomizationAttributesUpdateDTO,
    EquipmentCustomizationAttributesUpdateDTOFromJSON,
    EquipmentCustomizationAttributesUpdateDTOToJSON,
    OptionCustomizationLocalizationsDTO,
    OptionCustomizationLocalizationsDTOFromJSON,
    OptionCustomizationLocalizationsDTOToJSON,
    OptionCustomizationVideoLocalizationsDTO,
    OptionCustomizationVideoLocalizationsDTOFromJSON,
    OptionCustomizationVideoLocalizationsDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models';

export interface DeleteEquipmentCustomizationRequest {
    key: string;
}

export interface GetAttributesOfEquipmentCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfEquipmentCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetLinksOfEquipmentCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfDescriptionOfEquipmentCustomizationRequest {
    key: string;
}

export interface GetLocalizationsOfNameOfEquipmentCustomizationRequest {
    key: string;
}

export interface GetLocalizationsOfVideoOfEquipmentCustomizationRequest {
    key: string;
}

export interface UpdateAttributesOfEquipmentCustomizationRequest {
    key: string;
    equipmentCustomizationAttributesUpdateDTO: EquipmentCustomizationAttributesUpdateDTO;
}

export interface UpdateDescriptionOfDefaultValueOfEquipmentCustomizationRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfDefaultValueOfEquipmentCustomizationRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateVideoOfDefaultValueOfEquipmentCustomizationRequest {
    key: string;
    customizationVideoUpdateDTO: CustomizationVideoUpdateDTO;
}

export interface UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    customizationVideoUpdateDTO: CustomizationVideoUpdateDTO;
}

/**
 * 
 */
export class EquipmentCustomizationDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a EquipmentCustomization by a given key
     */
    async deleteEquipmentCustomizationRaw(requestParameters: DeleteEquipmentCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a EquipmentCustomization by a given key
     */
    async deleteEquipmentCustomization(requestParameters: DeleteEquipmentCustomizationRequest): Promise<void> {
        await this.deleteEquipmentCustomizationRaw(requestParameters);
    }

    /**
     * Get attributes of EquipmentCustomization with given key
     */
    async getAttributesOfEquipmentCustomizationRaw(requestParameters: GetAttributesOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<EquipmentCustomizationAttributesDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfEquipmentCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentCustomizationAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of EquipmentCustomization with given key
     */
    async getAttributesOfEquipmentCustomization(requestParameters: GetAttributesOfEquipmentCustomizationRequest): Promise<EquipmentCustomizationAttributesDTO> {
        const response = await this.getAttributesOfEquipmentCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of EquipmentCustomization with given key
     */
    async getBaseInformationOfEquipmentCustomizationRaw(requestParameters: GetBaseInformationOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfEquipmentCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of EquipmentCustomization with given key
     */
    async getBaseInformationOfEquipmentCustomization(requestParameters: GetBaseInformationOfEquipmentCustomizationRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfEquipmentCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get links of EquipmentCustomization with given key
     */
    async getLinksOfEquipmentCustomizationRaw(requestParameters: GetLinksOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<CustomizationLinksDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLinksOfEquipmentCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinksOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/links`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomizationLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of EquipmentCustomization with given key
     */
    async getLinksOfEquipmentCustomization(requestParameters: GetLinksOfEquipmentCustomizationRequest): Promise<CustomizationLinksDTO> {
        const response = await this.getLinksOfEquipmentCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfDescription of EquipmentCustomization with given key
     */
    async getLocalizationsOfDescriptionOfEquipmentCustomizationRaw(requestParameters: GetLocalizationsOfDescriptionOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfDescriptionOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/description/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfDescription of EquipmentCustomization with given key
     */
    async getLocalizationsOfDescriptionOfEquipmentCustomization(requestParameters: GetLocalizationsOfDescriptionOfEquipmentCustomizationRequest): Promise<OptionCustomizationLocalizationsDTO> {
        const response = await this.getLocalizationsOfDescriptionOfEquipmentCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of EquipmentCustomization with given key
     */
    async getLocalizationsOfNameOfEquipmentCustomizationRaw(requestParameters: GetLocalizationsOfNameOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfName of EquipmentCustomization with given key
     */
    async getLocalizationsOfNameOfEquipmentCustomization(requestParameters: GetLocalizationsOfNameOfEquipmentCustomizationRequest): Promise<OptionCustomizationLocalizationsDTO> {
        const response = await this.getLocalizationsOfNameOfEquipmentCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfVideo of EquipmentCustomization with given key
     */
    async getLocalizationsOfVideoOfEquipmentCustomizationRaw(requestParameters: GetLocalizationsOfVideoOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationVideoLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfVideoOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/video/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationVideoLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfVideo of EquipmentCustomization with given key
     */
    async getLocalizationsOfVideoOfEquipmentCustomization(requestParameters: GetLocalizationsOfVideoOfEquipmentCustomizationRequest): Promise<OptionCustomizationVideoLocalizationsDTO> {
        const response = await this.getLocalizationsOfVideoOfEquipmentCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing EquipmentCustomization
     */
    async updateAttributesOfEquipmentCustomizationRaw(requestParameters: UpdateAttributesOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfEquipmentCustomization.');
        }

        if (requestParameters.equipmentCustomizationAttributesUpdateDTO === null || requestParameters.equipmentCustomizationAttributesUpdateDTO === undefined) {
            throw new runtime.RequiredError('equipmentCustomizationAttributesUpdateDTO','Required parameter requestParameters.equipmentCustomizationAttributesUpdateDTO was null or undefined when calling updateAttributesOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EquipmentCustomizationAttributesUpdateDTOToJSON(requestParameters.equipmentCustomizationAttributesUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing EquipmentCustomization
     */
    async updateAttributesOfEquipmentCustomization(requestParameters: UpdateAttributesOfEquipmentCustomizationRequest): Promise<void> {
        await this.updateAttributesOfEquipmentCustomizationRaw(requestParameters);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing EquipmentCustomization
     */
    async updateDescriptionOfDefaultValueOfEquipmentCustomizationRaw(requestParameters: UpdateDescriptionOfDefaultValueOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDescriptionOfDefaultValueOfEquipmentCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfDefaultValueOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/default-value/description`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing EquipmentCustomization
     */
    async updateDescriptionOfDefaultValueOfEquipmentCustomization(requestParameters: UpdateDescriptionOfDefaultValueOfEquipmentCustomizationRequest): Promise<void> {
        await this.updateDescriptionOfDefaultValueOfEquipmentCustomizationRaw(requestParameters);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing EquipmentCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRaw(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/description`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing EquipmentCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRequest): Promise<void> {
        await this.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfDefaultValue of an existing EquipmentCustomization
     */
    async updateNameOfDefaultValueOfEquipmentCustomizationRaw(requestParameters: UpdateNameOfDefaultValueOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfDefaultValueOfEquipmentCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfDefaultValueOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/default-value/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfDefaultValue of an existing EquipmentCustomization
     */
    async updateNameOfDefaultValueOfEquipmentCustomization(requestParameters: UpdateNameOfDefaultValueOfEquipmentCustomizationRequest): Promise<void> {
        await this.updateNameOfDefaultValueOfEquipmentCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing EquipmentCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRaw(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing EquipmentCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRequest): Promise<void> {
        await this.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRaw(requestParameters);
    }

    /**
     * Updates videoOfDefaultValue of an existing EquipmentCustomization
     */
    async updateVideoOfDefaultValueOfEquipmentCustomizationRaw(requestParameters: UpdateVideoOfDefaultValueOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateVideoOfDefaultValueOfEquipmentCustomization.');
        }

        if (requestParameters.customizationVideoUpdateDTO === null || requestParameters.customizationVideoUpdateDTO === undefined) {
            throw new runtime.RequiredError('customizationVideoUpdateDTO','Required parameter requestParameters.customizationVideoUpdateDTO was null or undefined when calling updateVideoOfDefaultValueOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/default-value/video`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomizationVideoUpdateDTOToJSON(requestParameters.customizationVideoUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates videoOfDefaultValue of an existing EquipmentCustomization
     */
    async updateVideoOfDefaultValueOfEquipmentCustomization(requestParameters: UpdateVideoOfDefaultValueOfEquipmentCustomizationRequest): Promise<void> {
        await this.updateVideoOfDefaultValueOfEquipmentCustomizationRaw(requestParameters);
    }

    /**
     * Updates videoOfValueOfConditionalValuesByConditionalValueKey of an existing EquipmentCustomization
     */
    async updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRaw(requestParameters: UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization.');
        }

        if (requestParameters.customizationVideoUpdateDTO === null || requestParameters.customizationVideoUpdateDTO === undefined) {
            throw new runtime.RequiredError('customizationVideoUpdateDTO','Required parameter requestParameters.customizationVideoUpdateDTO was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/equipment-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/video`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomizationVideoUpdateDTOToJSON(requestParameters.customizationVideoUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates videoOfValueOfConditionalValuesByConditionalValueKey of an existing EquipmentCustomization
     */
    async updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomization(requestParameters: UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRequest): Promise<void> {
        await this.updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfEquipmentCustomizationRaw(requestParameters);
    }

}
