/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarketplaceDTO,
    MarketplaceDTOFromJSON,
    MarketplaceDTOFromJSONTyped,
    MarketplaceDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface BootInfoDTO
 */
export interface BootInfoDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof BootInfoDTO
     */
    authorities: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BootInfoDTO
     */
    supportedLanguageTags: Array<string>;
    /**
     * 
     * @type {Array<MarketplaceDTO>}
     * @memberof BootInfoDTO
     */
    supportedMarketplaces: Array<MarketplaceDTO>;
}

export function BootInfoDTOFromJSON(json: any): BootInfoDTO {
    return BootInfoDTOFromJSONTyped(json, false);
}

export function BootInfoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BootInfoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorities': json['authorities'],
        'supportedLanguageTags': json['supportedLanguageTags'],
        'supportedMarketplaces': ((json['supportedMarketplaces'] as Array<any>).map(MarketplaceDTOFromJSON)),
    };
}

export function BootInfoDTOToJSON(value?: BootInfoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorities': value.authorities,
        'supportedLanguageTags': value.supportedLanguageTags,
        'supportedMarketplaces': ((value.supportedMarketplaces as Array<any>).map(MarketplaceDTOToJSON)),
    };
}


